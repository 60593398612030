import { formatUnixTime } from "../../../global/utils/unixToLocalTime";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import SortIcon from "../../../global/components/SortIcon";

export const OrderColumns = (
  hoveredRow,
  setShowModal,
  setSelectedRowKeys,
  setParams,
  params
) => {
  const loggedInUser = JSON.parse(sessionStorage.getItem("userData"));
  return [
    {
      title: "No",
      dataIndex: "OrderNo",
      key: "OrderNo",
      sorter: (a, b) => a?.OrderNo - b?.OrderNo,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="OrderNo"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "OrderNo",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "OEM",
      dataIndex: "OemNo",
      key: "OemNo",
      hidden: loggedInUser.role_name != "SuperAdmin",
      sorter: (a, b) => a?.OemNo - b?.OemNo,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="OemNo"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "OemNo",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Dealer",
      dataIndex: "DealerNo",
      hidden:
        loggedInUser.role_name == "DealerAdmin" ||
        loggedInUser.role_name == "DealerUser",
      key: "DealerNo",
      sorter: (a, b) => a?.DealerNo - b?.DealerNo,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="DealerNo"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "DealerNo",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Account",
      dataIndex: "AccountNo",
      hidden: loggedInUser.role_name == "DealerUser",
      key: "AccountNo",
      sorter: (a, b) => a?.AccountNo?.length - b?.AccountNo?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="AccountNo"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "AccountNo",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Name",
      dataIndex: "OrderName",
      key: "OrderName",
      sorter: (a, b) => a?.OrderName?.length - b?.OrderName?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="OrderName"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "OrderName",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Description",
      dataIndex: "OrderDesc",
      key: "OrderDesc",
      sorter: (a, b) => a?.OrderDesc?.length - b?.OrderDesc?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="OrderDesc"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "OrderDesc",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Amount",
      dataIndex: "Amount",
      key: "Amount",
      sorter: (a, b) => a?.Amount?.length - b?.Amount?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="Amount"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "Amount",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Confirmed",
      dataIndex: "Paid",
      key: "Paid",
      sorter: (a, b) => a?.Paid?.length - b?.Paid?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="Paid"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "Paid",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Order Time",
      dataIndex: "OrderTime",
      key: "OrderTime",
      render: (text) => <div>{text ? formatUnixTime(text) : ""}</div>,
      sorter: (a, b) => new Date(a.OrderTime) - new Date(b.OrderTime),
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="OrderTime"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "OrderTime",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Action",
      fixed: "right",
      width: 100,
      align: "center",
      render: (text, record, rowIndex) =>
        loggedInUser.role_value !== 1 &&
        loggedInUser.role_value !== 2 &&
        record?.Paid !== "Yes" ? (
          <div className="flex justify-center items-center">
            <div className="mr-1">
              <Link
                to={`/orderinfo/edit/${record?.OrderNo}`}
                onClick={(e) => e.stopPropagation()}
                className="text-[#50565E] hover:bg-gray-200  hover:text-gray-700 p-1 rounded-[4px]"
              >
                <EditOutlined />
              </Link>
            </div>
            <div
              onClick={(e) => {
                e.stopPropagation();
                setShowModal(true);
                setSelectedRowKeys([record?.OrderNo]);
              }}
              className="hover:cursor-pointer"
            >
              <DeleteOutlined className="text-[#50565E] hover:bg-gray-200 p-1 rounded-[4px]" />
            </div>
          </div>
        ) : null,
    },
  ];
};

export const OrderItemColumns = (
  hoveredRow,
  setShowModal,
  setSelectedRowKeys,
  setShowForm,
  setFormType,
  setSelectedOrderItemNo
) => {
  return [
    {
      title: "No",
      dataIndex: "ItemNo",
      key: "ItemNo",
    },
    {
      title: "Customer",
      dataIndex: "CustomerNo",
      key: "CustomerNo",
      width: 400,
    },
    {
      title: "License",
      dataIndex: "LicenseNo",
      key: "LicenseNo",
    },
    {
      title: "Price",
      dataIndex: "Price",
      key: "Price",
    },
    // {
    //   title: "Used",
    //   dataIndex: "Used",
    //   key: "Used",
    // },
    // {
    //   title: "Used MAC",
    //   dataIndex: "UsedMac",
    //   key: "UsedMac",
    // },
    // {
    //   title: "Used Time",
    //   dataIndex: "UsedTime",
    //   key: "UsedTime",
    //   render: (text) => <div>{text ? formatUnixTime(text) : ""}</div>,
    // },
    // {
    //   title: "",
    //   key: "action",
    //   width: "0%",
    //   fixed: "right",
    //   render: (text, record, rowIndex) =>
    //     hoveredRow === rowIndex ? (
    //       <div className="action-buttons">
    //         {record?.Paid == "Yes" ? (
    //           <div></div>
    //         ) : (
    //           <>
    //             <div
    //               className=""
    //               onClick={(e) => {
    //                 setShowForm(true);
    //                 setFormType("Edit");
    //                 setSelectedOrderItemNo({ ItemNo: record?.ItemNo });
    //                 e.stopPropagation();
    //               }}
    //             >
    //               {/* className=" hover:bg-gray-200 !text-[#50565E] px-2 py-1 rounded-[4px]" */}

    //               <EditOutlined className=" hover:bg-gray-200 !text-[#50565E] px-2 py-2 rounded-[4px]" />
    //             </div>
    //             <div
    //               onClick={(e) => {
    //                 e.stopPropagation();
    //                 setShowModal(true);
    //                 setSelectedRowKeys([record?.ItemNo]);
    //               }}
    //               className="hover:cursor-pointer"
    //             >
    //               <DeleteOutlined className="text-[#50565E] hover:bg-gray-200 px-2 py-2 rounded-[4px]" />
    //             </div>
    //           </>
    //         )}
    //       </div>
    //     ) : null,
    // },
  ];
};
