import React from "react";
const OptionsIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#CCC"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M9.667 18.333h-.228c-2.716 0-4.077 0-5.02-.665a3.419 3.419 0 0 1-.712-.67C3 16.108 3 14.83 3 12.271v-2.12c0-2.47 0-3.705.39-4.69.63-1.586 1.958-2.836 3.642-3.428 1.047-.367 2.358-.367 4.983-.367 1.498 0 2.248 0 2.847.21.962.338 1.721 1.052 2.08 1.958.225.563.225 1.27.225 2.68v2.652"
    />
    <path
      stroke="#CCC"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M3 10a2.778 2.778 0 0 1 2.777-2.778c.555 0 1.21.097 1.749-.047a1.392 1.392 0 0 0 .982-.983c.145-.54.048-1.194.048-1.749a2.777 2.777 0 0 1 2.777-2.776m2.917 9.166c-1.188 0-1.958.758-2.868 1.034-.37.111-.555.168-.63.247-.075.08-.097.194-.141.425-.47 2.474.556 4.761 3.004 5.65.262.097.394.144.636.144s.372-.047.636-.143c2.446-.89 3.471-3.177 3.002-5.65-.044-.232-.066-.347-.14-.426-.075-.078-.26-.136-.63-.247-.912-.276-1.681-1.034-2.869-1.034Z"
    />
  </svg>
);
export default OptionsIcon;
