import { useNavigate, useParams } from "react-router-dom";
import { Button, Divider } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import useGet from "../../../global/hooks/useGet";
import { User } from "../../../global/components/User";
import WelcomeBar from "../../License/Components/WelcomeBar";
import MacIcon from "../../../assets/images/vectors/MacIcon";
import ProductIcon from "../../../assets/images/vectors/ProductIcon";
import ModelIcon from "../../../assets/images/vectors/ModelIcon";
import OptionsIcon from "../../../assets/images/vectors/OptionsIcon";
import OemIcon from "../../../assets/images/vectors/OemIcon";
import TypeIcon from "../../../assets/images/vectors/TypeIcon";
import CameraIcon from "../../../assets/images/vectors/CameraIcon";
import TrilogyIcon from "../../../assets/images/vectors/TrilogyIcon";
import SubnodeIcon from "../../../assets/images/vectors/SubnodeIcon";
import IntercomIcon from "../../../assets/images/vectors/IntercomIcon";
import ChannelIcon from "../../../assets/images/vectors/ChannelIcon";
import FacegateIcon from "../../../assets/images/vectors/FacegateIcon";
import SideCards from "../../License/Components/SideCards";
import useOemData from "../../../global/hooks/useOemData";

export const OrderedItemForm = ({ itemNo }) => {
  const { oemNo } = useOemData();
  const customerInfo = JSON.parse(sessionStorage.getItem("customerInfo"));
  const { mac } = customerInfo;
  const url = `${process.env.REACT_APP_API_URL}/api/orderitem/${itemNo}`;
  const { data, loading, error } = useGet(url);

  return (
    <div className="">
      <div className="bg-white rounded-md">
        <p className="text-[16px] text-center font-medium mb-4">
          {" "}
          License Information
        </p>

        <div>
          <p className="">Device</p>
          <Divider className="m-0" />
          <p className="text-sm p-2 my-4 flex items-center">
            <span className="bg-[#F4F5F9] p-2 rounded-xl">
              <MacIcon />
            </span>
            <span className="text-[#808080] font-light mx-2">MAC:</span> {mac}
          </p>
        </div>

        <div>
          <p className="">License Information</p>
          <Divider className="m-0" />
          <div className="flex mt-4">
            <div className="w-1/2">
              <p className="text-sm p-2 flex items-center">
                <span className="bg-[#F4F5F9] p-2 rounded-xl">
                  <ProductIcon />
                </span>
                <span className="text-[#808080] font-light mx-2">Product:</span>{" "}
                {data?.data?.LicenseInfo?.ProductName}
              </p>
              <p className="text-sm p-2 flex items-center mb-4">
                <span className="bg-[#F4F5F9] p-2 rounded-xl">
                  <ModelIcon />
                </span>
                <span className="text-[#808080] font-light mx-2">Model:</span>{" "}
                {data?.data?.LicenseInfo?.ModelName}
              </p>
            </div>
            <div className="w-1/2">
              <p className="text-sm p-2 flex items-center">
                <span className="bg-[#F4F5F9] p-2 rounded-xl">
                  <OemIcon />
                </span>
                <span className="text-[#808080] font-light mx-2 ">OEM:</span>{" "}
                {data?.data?.LicenseInfo?.OemName}
              </p>
              <p className="text-sm p-2 flex items-center mb-4">
                <span className="bg-[#F4F5F9] p-2 rounded-xl">
                  <TypeIcon />
                </span>
                <span className="text-[#808080] font-light mx-2">Type:</span>{" "}
                {data?.data?.LicenseInfo?.Type}
              </p>
            </div>
          </div>
        </div>

        <div>
          <p className="">License Options</p>
          <Divider className="m-0" />
          <p className="text-sm p-2 flex items-center my-4">
            <span className="bg-[#F4F5F9] p-2 rounded-xl">
              <OptionsIcon />
            </span>
            <span className="text-[#808080] font-light mx-2">Options:</span>{" "}
            {data?.data?.LicenseInfo?.OptionNames_arr?.map((option) => {
              return (
                <span className="px-2 py-1 bg-gray-200/55 rounded-md mr-1">
                  {option}
                </span>
              );
            })}
          </p>
        </div>

        <div>
          <p className="">License Devices</p>
          <Divider className="m-0" />
          <div className="flex mt-4">
            <div className="w-1/2">
              <p className="text-sm p-2 flex items-center mb-4">
                <span className="bg-[#F4F5F9] p-2 rounded-xl">
                  <CameraIcon />
                </span>
                <span className="text-[#808080] font-light mx-2">Camera:</span>{" "}
                {data?.data?.LicenseInfo?.Camera}
              </p>
              <p className="text-sm p-2 flex items-center mb-4">
                <span className="bg-[#F4F5F9] p-2 rounded-xl">
                  <TrilogyIcon />
                </span>
                <span className="text-[#808080] font-light mx-2">Lockset:</span>{" "}
                {data?.data?.LicenseInfo?.Lockset}
              </p>
              <p className="text-sm p-2 flex items-center mb-4">
                <span className="bg-[#F4F5F9] p-2 rounded-xl">
                  <SubnodeIcon />
                </span>
                <span className="text-[#808080] font-light mx-2">Subnode:</span>{" "}
                {data?.data?.LicenseInfo?.Subnode}
              </p>
              <p className="text-sm p-2 flex items-center">
                <span className="bg-[#F4F5F9] p-2 rounded-xl">
                  <IntercomIcon />
                </span>
                <span className="text-[#808080] font-light mx-2">
                  Intercom:
                </span>{" "}
                {data?.data?.LicenseInfo?.Intercom}
              </p>
            </div>
            <div className="w-1/2">
              <p className="text-sm p-2 flex items-center mb-4">
                <span className="bg-[#F4F5F9] p-2 rounded-xl">
                  <ChannelIcon />
                </span>
                <span className="text-[#808080] font-light mx-2">Channel:</span>{" "}
                {data?.data?.LicenseInfo?.Channel}
              </p>
              <p className="text-sm p-2 flex items-center mb-4">
                <span className="bg-[#F4F5F9] p-2 rounded-xl">
                  <FacegateIcon />
                </span>
                <span className="text-[#808080] font-light mx-2">
                  Facegate:
                </span>{" "}
                {data?.data?.LicenseInfo?.Facegate}
              </p>
              <p className="text-sm p-2 flex items-center">
                <span className="bg-[#F4F5F9] p-2 rounded-xl">
                  <TrilogyIcon />
                </span>
                <span className="text-[#808080] font-light mx-2">
                  Trilogy Lockset:
                </span>{" "}
                {data?.data?.LicenseInfo?.ContLock}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
