import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import SortIcon from "../../../global/components/SortIcon";

export const OemInfoColumns = (
  hoveredRow,
  setShowModal,
  setSelectedRowKeys,
  setParams,
  params
) => {
  return [
    {
      title: "No",
      dataIndex: "OemInfoNo",
      key: "OemInfoNo",
      sorter: (a, b) => a?.OemInfoNo - b?.OemInfoNo,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="OemInfoNo"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "OemInfoNo",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Name",
      dataIndex: "OemInfoName",
      key: "OemInfoName",
      sorter: (a, b) => a?.OemInfoName - b?.OemInfoName,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="OemInfoName"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "OemInfoName",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Description",
      dataIndex: "OemInfoDesc",
      key: "OemInfoDesc",
      sorter: (a, b) => a?.OemInfoDesc?.length - b?.OemInfoDesc?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="OemInfoDesc"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "OemInfoDesc",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Action",
      fixed: "right",
      width: 100,
      align: "center",
      render: (text, record, rowIndex) => (
        <div className="flex justify-center items-center">
          <div className="mr-1">
            <Link
              to={`/oeminfo/edit/${record?.OemInfoNo}`}
              onClick={(e) => e.stopPropagation()}
              className=" hover:bg-gray-200 !text-[#50565E] hover:text-gray-700  p-1 rounded-[4px]"
            >
              <EditOutlined />
            </Link>
          </div>
          <div
            onClick={(e) => {
              e.stopPropagation();
              setShowModal(true);
              setSelectedRowKeys([record?.OemInfoNo]);
            }}
            className="hover:cursor-pointer"
          >
            <DeleteOutlined className="text-[#50565E] hover:bg-gray-200 p-1 rounded-[4px]" />
          </div>
        </div>
      ),
    },
  ];
};
