import React, { createContext, useContext, useState } from "react";

export const LicenseContext = createContext([]);

export const LicenseProvider = ({ children }) => {
  const [selectedLicense, setSelectedLicense] = useState();

  return (
    <LicenseContext.Provider value={{ selectedLicense, setSelectedLicense }}>
      {children}
    </LicenseContext.Provider>
  );
};
