import { useState } from "react";
import axios from "axios";

const usePostLogin = (url) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const postData = async (body) => {
    try {
      setLoading(true);
      const response = await axios.post(url, body, {
        // headers: {
        //   Authorization: `Bearer ${access_token}`,
        // },
      });
      setData(response?.data?.data ? response?.data?.data : response?.data);
      setError(null);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  return { data, loading, error, postData };
};

export default usePostLogin;
