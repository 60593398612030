import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { showToast } from "../utils/toast";
import { faWarning } from "@fortawesome/free-solid-svg-icons";

const AutoLogout = ({ timeout }) => {
  const [isActive, setIsActive] = useState(true);
  const timerRef = useRef(null);
  const navigate = useNavigate();

  const isLoggedIn = sessionStorage.getItem("userData");

  const resetTimer = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      setIsActive(false);
    }, timeout);
  };

  useEffect(() => {
    if (!isActive && isLoggedIn) {
      sessionStorage.removeItem("userData");
      sessionStorage.removeItem("customerInfo");
      showToast("Session expired. Please log in again!", "error", faWarning);
      navigate("/");
    }
  }, [isActive]);

  useEffect(() => {
    const events = ["mousemove", "keydown", "click", "scroll", "touchstart"];

    const handleActivity = () => {
      setIsActive(true);
      resetTimer();
    };

    events.forEach((event) => window.addEventListener(event, handleActivity));

    resetTimer();

    return () => {
      events.forEach((event) =>
        window.removeEventListener(event, handleActivity)
      );
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, []);

  return null;
};

export default AutoLogout;
