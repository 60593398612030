import React, { useEffect, useState } from "react";
import WeatherIcon from "../../../assets/images/vectors/WeatherIcon";
const WelcomeBar = () => {
  const [formattedDateTime, setFormattedDateTime] = useState({});
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const { account_name } = userData;

  //For time formatting
  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date();

      const formattedDate = now.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      });

      const formattedTime = now.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });

      setFormattedDateTime({ formattedDate, formattedTime });
    }, 1000);

    // Clean up the interval on component unmount
    return () => clearInterval(timer);
  }, []);

  return (
    <div className="bg-white flex justify-between items-center my-2 px-12 py-4 rounded-md">
      <div>
        <p className="text-[#808080] text-sm font-light mb-2">Dashboard</p>
        <p className="font-semibold">
          Welcome,{" "}
          <span className="text-[#2270B8]">
            {account_name ? account_name : ""}
          </span>{" "}
          to our license system!
        </p>
      </div>
      <div className="flex">
        <WeatherIcon className="mt-1" />
        <div className="ml-4">
          <p className="text-[#808080] text-[14px]">
            {formattedDateTime.formattedTime}
          </p>
          <p>{formattedDateTime.formattedDate}</p>
        </div>
      </div>
    </div>
  );
};

export default WelcomeBar;
