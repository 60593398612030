import React from "react";
const TrilogyIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#CCC"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M18.833 10A8.333 8.333 0 1 1 10.5 1.667 8.333 8.333 0 0 1 18.833 10Z"
    />
    <path
      stroke="#CCC"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M10.5 10.833a1.667 1.667 0 1 0 0-3.334 1.667 1.667 0 0 0 0 3.334Zm0 0v2.5"
    />
  </svg>
);
export default TrilogyIcon;
