import React from "react";
const MacIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#CCC"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M3.833 10c0-3.143 0-4.714.977-5.69s2.547-.977 5.69-.977c3.142 0 4.714 0 5.69.977s.977 2.547.977 5.69c0 3.142 0 4.714-.977 5.69s-2.548.977-5.69.977c-3.143 0-4.714 0-5.69-.977s-.977-2.548-.977-5.69Z"
    />
    <path
      stroke="#CCC"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M7.167 1.667v1.666m6.666-1.666v1.666M10.5 1.667v1.666M7.167 16.667v1.666m3.333-1.666v1.666m3.333-1.666v1.666m5-5h-1.666M3.833 6.667H2.167m1.666 6.666H2.167M3.833 10H2.167m16.666-3.333h-1.666M18.833 10h-1.666M6.943 13.557c.61.61 1.593.61 3.557.61.658 0 1.206 0 1.667-.024l2.476-2.476c.024-.461.024-1.009.024-1.667 0-1.964 0-2.947-.61-3.557-.61-.61-1.593-.61-3.557-.61s-2.947 0-3.557.61c-.61.61-.61 1.593-.61 3.557s0 2.947.61 3.557Z"
    />
  </svg>
);
export default MacIcon;
