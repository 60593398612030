import React from "react";
const FacegateIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="#CCC"
      d="M8.5.5a7.5 7.5 0 1 0 0 15 7.5 7.5 0 0 0 0-15Zm4.596 12.096A6.477 6.477 0 1 1 3.95 3.423a6.477 6.477 0 0 1 9.146 9.173Z"
    />
    <path
      fill="#CCC"
      d="M4.5 6h1.25v1.25H4.5V6ZM11 6h1.25v1.25H11V6ZM7.75 9.592c0-.015.002-.03.006-.043L8.75 6.07v-.82h-1v.68l-.955 3.344a1.158 1.158 0 0 0 1.113 1.476H9.75v-1H7.908a.158.158 0 0 1-.158-.158Z"
    />
  </svg>
);
export default FacegateIcon;
