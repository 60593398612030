import { CheckCircleOutlined, SyncOutlined } from "@ant-design/icons";
import { Button, Col, Input, Row, Select } from "antd";
import { Link } from "react-router-dom";
import CustomDateTimePicker from "../../../global/components/inputWidgets/CustomDatePicker";
import useGet from "../../../global/hooks/useGet";
import { getModifiedResponse } from "../../../global/utils/responseModifier";

export const OrderItemFilter = ({
  filter,
  handleFilterChange,
  handleFilter,
  handleReset,
  setShowModal,
  selectedRowKeys,
  setFilter,
}) => {
  const loggedInUser = JSON.parse(sessionStorage.getItem("userData"));
  const optionUrl = `${process.env.REACT_APP_API_URL}/api/order/getoptions`;

  const {
    data: optionData,
    loading: optionDataLoading,
    error: optionDataError,
  } = useGet(optionUrl);

  const modifiedOems = getModifiedResponse(optionData, "oems");
  const modifiedDealer = getModifiedResponse(optionData, "dealers");

  const onSearch = (value) => {
    console.log("search:", value);
  };

  return (
    <div>
      <div className="mt-3">
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <Input
              className="input_text_3 mr10"
              name="ItemNo"
              placeholder="No"
              value={filter.ItemNo}
              onChange={handleFilterChange}
            />
          </Col>
          {loggedInUser.role_name == "SuperAdmin" && (
            <Col xs={24} sm={24} md={12} lg={8} xl={6}>
              <Select
                showSearch
                placeholder="OEM"
                optionFilterProp="OemNo"
                value={filter.OemNo || undefined}
                onChange={(value) => setFilter({ ...filter, OemNo: value })}
                onSearch={onSearch}
                options={modifiedOems}
                style={{ width: "100%" }}
              />
            </Col>
          )}
          {loggedInUser.role_name !== "DealerAdmin" &&
            loggedInUser.role_name !== "DealerUser" && (
              <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                <Select
                  showSearch
                  placeholder="Dealer"
                  optionFilterProp="DealerNo"
                  value={filter.DealerNo || undefined}
                  onChange={(value) =>
                    setFilter({ ...filter, DealerNo: value })
                  }
                  onSearch={onSearch}
                  options={modifiedDealer}
                  style={{ width: "100%" }}
                />
              </Col>
            )}
          {loggedInUser.role_name != "DealerUser" && (
            <Col xs={24} sm={24} md={12} lg={8} xl={6}>
              <Input
                className="input_text_3 mr10"
                name="AccountName"
                placeholder="Account"
                value={filter.AccountName}
                onChange={handleFilterChange}
              />
            </Col>
          )}
          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <Input
              className="input_text_3 mr10"
              name="OrderName"
              placeholder="Order"
              value={filter.OrderName}
              onChange={handleFilterChange}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <Select
              showSearch
              placeholder="Confirmed"
              optionFilterProp="Paid"
              value={filter.Paid || undefined}
              onChange={(value) => setFilter({ ...filter, Paid: value })}
              onSearch={onSearch}
              options={[
                {
                  value: "1",
                  label: "Yes",
                },
                {
                  value: "0",
                  label: "No",
                },
              ]}
              style={{ width: "100%" }}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <CustomDateTimePicker
              name="OrderTime"
              placeholder="Order Time"
              format="YYYY-MM-DD hh:mm A"
              value={filter.OrderTime}
              onChange={(value) => setFilter({ ...filter, OrderTime: value })}
              // onChange={(value) => console.log(value)}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <Input
              className="input_text_3 mr10"
              placeholder="Customer"
              value={filter.CustomerName}
              onChange={handleFilterChange}
              name="CustomerName"
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <Input
              className="input_text_3 mr10"
              placeholder="License"
              value={filter.LicenseName}
              onChange={handleFilterChange}
              name="LicenseName"
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <Select
              showSearch
              placeholder="Used"
              optionFilterProp="Used"
              value={filter.Used || undefined}
              onChange={(value) => setFilter({ ...filter, Used: value })}
              onSearch={onSearch}
              options={[
                {
                  value: "1",
                  label: "Yes",
                },
                {
                  value: "0",
                  label: "No",
                },
              ]}
              style={{ width: "100%" }}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <Input
              className="input_text_3 mr10"
              placeholder="Used Mac"
              value={filter.UsedMac}
              onChange={handleFilterChange}
              name="UsedMac"
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <CustomDateTimePicker
              name="UsedTime"
              placeholder="Used Time"
              format="YYYY-MM-DD hh:mm A"
              value={filter.UsedTime}
              onChange={(value) => setFilter({ ...filter, UsedTime: value })}
              // onChange={(value) => console.log(value)}
            />
          </Col>
        </Row>
      </div>
      <div className="fw fl flex justify-between">
        <div className="my-4">
          <Button
            type="primary"
            className="mr-2 w-[100px] h-[30px]"
            onClick={handleFilter}
          >
            <CheckCircleOutlined />
            Apply
          </Button>
          <Button className="reset-button" onClick={handleReset}>
            <SyncOutlined />
            Reset
          </Button>
        </div>
      </div>
    </div>
  );
};
