import { faCheckCircle, faWarning } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { Form, Button, Col, Row } from "antd";
import useGet from "../../../global/hooks/useGet";
import usePost from "../../../global/hooks/usePost";
import usePut from "../../../global/hooks/usePut";
import CustomInput from "../../../global/components/inputWidgets/CustomInput";
import { useNavigate, useParams } from "react-router-dom";
import { showToast } from "../../../global/utils/toast";
import CommonBreadcrumb from "../../../global/components/CommonBreadcrumb";
import CommonButton from "../../../global/components/CommonButton";
import {
  CheckCircleOutlined,
  CloseOutlined,
  DesktopOutlined,
  UnorderedListOutlined,
  EditOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import useDelete from "../../../global/hooks/useDelete";
import ConfirmModal from "../../../global/components/ConfirmModal";

export const OemInfoForm = ({ type }) => {
  const [showModal, setShowModal] = useState(false);
  const [form] = Form.useForm();
  const { id } = useParams();
  const navigate = useNavigate();

  const url = id && `${process.env.REACT_APP_API_URL}/api/oeminfo/${id}`;
  const postUrl = `${process.env.REACT_APP_API_URL}/api/oeminfo/create`;
  const putUrl = `${process.env.REACT_APP_API_URL}/api/oeminfo/update/${id}`;
  const deleteUrl = `${process.env.REACT_APP_API_URL}/api/oeminfo/delete/${id}`;

  const { data, loading, error, refetch } = useGet(url);
  const {
    data: postData,
    loading: postLoading,
    error: postError,
    postData: postAction,
  } = usePost(postUrl);
  const {
    data: putData,
    loading: putLoading,
    error: putError,
    putData: putAction,
  } = usePut(putUrl);

  const {
    data: deletedData,
    loading: deletedDataLoading,
    error: deletedDataError,
    deleteData,
  } = useDelete(deleteUrl);

  const onFinish = async (values) => {
    try {
      if (id) {
        await putAction(values);
      } else {
        await postAction(values);
      }
    } catch (error) {
      showToast("Something went wrong, Please try again!", "error", faWarning);
      console.log(error);
    }
  };

  const onReset = () => {
    // form.resetFields();
    navigate("/oeminfolist");
  };

  const onDeleteConfirm = async () => {
    try {
      await deleteData();
    } catch (error) {
      showToast("Failed!", "error", faWarning);
    }
    setShowModal(false);
  };

  //toat for create
  useEffect(() => {
    if (postError) {
      showToast(postError?.response?.data?.message, "error", faWarning);
    } else if (!postLoading && !postError && postData) {
      showToast(postData?.message, "success", faCheckCircle);
      setTimeout(() => {
        navigate(-1);
      }, 200);
    }
  }, [postError, postData, postLoading]);

  //toat for update
  useEffect(() => {
    if (putError) {
      showToast(putError?.response?.data?.message, "error", faWarning);
    } else if (!putLoading && !putError && putData) {
      showToast(putData?.message, "success", faCheckCircle);
      refetch();
      setTimeout(() => {
        navigate(-1);
      }, 200);
    }
  }, [putData, putLoading, putError]);

  // Set default field value
  useEffect(() => {
    if (!id) {
      form.setFieldsValue({
        OemInfoName: "Basic Info",
        OemInfoDesc: "Basic Info",
        LicenseAddr: "device.sicunet.com",
        LicensePort: "8000",
        CloudAddr: "jupiter.sicunet.com",
        CloudPort: "8080",
        StorageAddr: "jupiter.sicunet.com",
        StoragePort: "22",
        StorageUser: "jupiter",
        StoragePass: "jupiter",
        SmtpAddr: "smtp.mailgun.org",
        SmtpPort: "587",
        SmtpUser: "postmaster@remote-manager.net",
        SmtpPass: "618855",
        UpdateAddr: "jupiter.sicunet.com",
        UpdatePort: "22",
        UpdateUser: "jupiter",
        UpdatePass: "jupiter",
      });
    }
  }, []);

  useEffect(() => {
    if (!id || loading) return;
    const getAccountInfoById = () => {
      try {
        if (data?.data) {
          form.setFieldsValue({
            OemInfoName: String(data?.data?.OemInfoName),
            OemInfoDesc: data.data.OemInfoDesc
              ? String(data.data.OemInfoDesc)
              : "",
            LicenseAddr: String(data.data.LicenseAddr),
            LicensePort: String(data.data.LicensePort),
            CloudAddr: String(data.data.CloudAddr),
            CloudPort: String(data.data.CloudPort),
            StorageAddr: String(data.data.StorageAddr),
            StoragePort: String(data.data.StoragePort),
            StorageUser: String(data.data.StorageUser),
            StoragePass: String(data.data.StoragePass),
            SmtpAddr: String(data.data.SmtpAddr),
            SmtpPort: String(data.data.SmtpPort),
            SmtpUser: String(data.data.SmtpUser),
            SmtpPass:
              data.data.SmtpPass != null ? String(data.data.SmtpPass) : "",
            UpdateAddr: String(data.data.UpdateAddr),
            UpdatePort: String(data.data.UpdatePort),
            UpdateUser: String(data.data.UpdateUser),
            UpdatePass: String(data.data.UpdatePass),
          });
        } else {
          console.warn("No data found for the given ID");
        }
      } catch (error) {
        if (!loading) {
          console.log({ error });
          showToast("Error fetching data!", "error", faWarning);
        }
      }
    };

    getAccountInfoById();
  }, [id, data, loading, form]);
  useEffect(() => {
    if (deletedData && !deletedDataError) {
      showToast(deletedData?.message, "success", faCheckCircle);
      refetch();
      navigate("/oeminfolist");
    } else if (deletedDataError) {
      showToast(deletedDataError?.response?.data?.message, "error", faWarning);
    }
  }, [deletedData, deletedDataError]);

  const breadcrumbItems = [
    {
      label: "OemInfo",
      href: "/oeminfolist",
    },
    {
      label: id ? (type === "info" ? "Information" : "Edit") : "Add",
      href: id
        ? type === "info"
          ? `/oeminfo/info/${id}`
          : `/oeminfo/edit/${id}`
        : "/oeminfo/add",
    },
  ];

  const buttonsData =
    type === "info"
      ? [
          {
            label: "Edit",
            icon: <EditOutlined />,
            onClick: () => {
              navigate(`/oeminfo/edit/${id}`);
            },
          },
          {
            label: "Delete",
            icon: <DeleteOutlined />,
            onClick: () => {
              setShowModal(true);
            },
          },
          {
            label: "List",
            icon: <UnorderedListOutlined />,
            onClick: () => {
              navigate("/oeminfolist");
            },
          },
        ]
      : [
          {
            label: "Apply",
            icon: <CheckCircleOutlined />,
            onClick: () => form.submit(),
          },
          {
            label: "Cancel",
            icon: <CloseOutlined />,
            onClick: onReset,
          },
        ];

  return (
    <>
      {
        <Form
          validateTrigger={"onChange"}
          form={form}
          onFinish={onFinish}
          scrollToFirstError
          layout={"vertical"}
          autoComplete={"off"}
        >
          <div>
            <div className="flex justify-between">
              <CommonBreadcrumb breadcrumbItems={breadcrumbItems} />
              <CommonButton buttonsData={buttonsData} />
            </div>
            {
              <>
                <div className="mt-6 rounded-[4px] border overflow-hidden">
                  <div className="bg-gray-100 p-2">
                    <InfoCircleOutlined />
                    <span className="text-md ml-2">OEM Info</span>
                  </div>
                  <div className="p-2">
                    <Row gutter={[16, 16]}>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <CustomInput
                          label="Name"
                          name="OemInfoName"
                          disabled={type === "info"}
                          required
                        />
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <CustomInput
                          label="Description"
                          name="OemInfoDesc"
                          disabled={type === "info"}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>

                <div className="mt-6 rounded-[4px] border  overflow-hidden">
                  <div className="bg-gray-100 p-2">
                    <DesktopOutlined />
                    <span className="text-md ml-2">License Server</span>
                  </div>
                  <div className="p-2">
                    <Row gutter={[16, 16]}>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <CustomInput
                          label="License Address"
                          name="LicenseAddr"
                          disabled={type === "info"}
                          required
                        />
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <CustomInput
                          label="License Port"
                          name="LicensePort"
                          disabled={type === "info"}
                          required
                        />
                      </Col>
                    </Row>
                  </div>
                </div>

                <div className="mt-6 rounded-[4px] border overflow-hidden">
                  <div className="bg-gray-100 p-2">
                    <DesktopOutlined />
                    <span className="text-md ml-2">Cloud Server</span>
                  </div>
                  <div className="p-2">
                    <Row gutter={[16, 16]}>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <CustomInput
                          label="Cloud Address"
                          name="CloudAddr"
                          disabled={type === "info"}
                          required
                        />
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <CustomInput
                          label="Cloud Port"
                          name="CloudPort"
                          disabled={type === "info"}
                          required
                        />
                      </Col>
                    </Row>
                  </div>
                </div>

                <div className="mt-6 rounded-[4px] border overflow-hidden">
                  <div className="bg-gray-100 p-2">
                    <DesktopOutlined />
                    <span className="text-md ml-2">Storage Server</span>
                  </div>
                  <div className="p-2">
                    <Row gutter={[16, 16]}>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <CustomInput
                          label="Storage Address"
                          name="StorageAddr"
                          disabled={type === "info"}
                          required
                        />
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <CustomInput
                          label="Storage Port"
                          name="StoragePort"
                          disabled={type === "info"}
                          required
                        />
                      </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <CustomInput
                          label="Storage User ID"
                          name="StorageUser"
                          disabled={type === "info"}
                          required
                        />
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <CustomInput
                          label="Storage Password"
                          name="StoragePass"
                          disabled={type === "info"}
                          required
                        />
                      </Col>
                    </Row>
                  </div>
                </div>

                <div className="mt-6 rounded-[4px] border overflow-hidden">
                  <div className="bg-gray-100 p-2">
                    <DesktopOutlined />
                    <span className="text-md ml-2">SMTP Server</span>
                  </div>
                  <div className="p-2">
                    <Row gutter={[16, 16]}>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <CustomInput
                          label="SMTP Address"
                          name="SmtpAddr"
                          disabled={type === "info"}
                          required
                        />
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <CustomInput
                          label="SMTP Port"
                          name="SmtpPort"
                          disabled={type === "info"}
                          required
                        />
                      </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <CustomInput
                          label="SMTP User ID"
                          name="SmtpUser"
                          disabled={type === "info"}
                          required
                        />
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <CustomInput
                          label="SMTP Password"
                          name="SmtpPass"
                          disabled={type === "info"}
                          required
                        />
                      </Col>
                    </Row>
                  </div>
                </div>

                <div className="mt-6 rounded-[4px] border overflow-hidden">
                  <div className="bg-gray-100 p-2">
                    <DesktopOutlined />
                    <span className="text-md ml-2">Update Server</span>
                  </div>
                  <div className="p-2">
                    <Row gutter={[16, 16]}>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <CustomInput
                          label="Update Address"
                          name="UpdateAddr"
                          disabled={type === "info"}
                          required
                        />
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <CustomInput
                          label="Update Port"
                          name="UpdatePort"
                          disabled={type === "info"}
                          required
                        />
                      </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <CustomInput
                          label="Update User ID"
                          name="UpdateUser"
                          disabled={type === "info"}
                          required
                        />
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <CustomInput
                          label="Update Password"
                          name="UpdatePass"
                          disabled={type === "info"}
                          required
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </>
            }
          </div>
        </Form>
      }
      <ConfirmModal
        showModal={showModal}
        setShowModal={setShowModal}
        onDeleteConfirm={onDeleteConfirm}
      />
    </>
  );
};
