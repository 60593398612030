import React from "react";
const CheckIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={41}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#34B847"
        fillRule="evenodd"
        d="M0 20.125a20 20 0 1 1 40 0 20 20 0 0 1-40 0Zm18.859 8.56 11.514-14.394-2.08-1.665-9.818 12.27L11.52 19.1 9.813 21.15l9.046 7.536Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 .125h40v40H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default CheckIcon;
