import moment from "moment";

export const formatUnixTime = (unixTime) => {
  return moment.unix(unixTime).format("YYYY-MM-DD hh:mm A"); // Format to 'YYYY-MM-DD HH:MM AM/PM'
};

export const convertToUnix = (isoDateString) => {
  return new Date(isoDateString).getTime() / 1000;
};

// export const formatLocalTime = (dateObject) => {
//   return moment(
//     new Date(
//       dateObject.$y,
//       dateObject.$M,
//       dateObject.$D,
//       dateObject.$H,
//       dateObject.$m
//     )
//   ).format("YYYY-MM-DD hh:mm A");
// };

export const formatUnixToLocal = (unixTimestamp) => {
  return moment.unix(unixTimestamp).format("YYYY-MM-DD hh:mm A");
};
