import SortIcon from "../../../global/components/SortIcon";
import { formatUnixTime } from "../../../global/utils/unixToLocalTime";

export const OrderItemColumn = (setParams, params) => {
  return [
    {
      title: "No",
      dataIndex: "ItemNo",
      key: "ItemNo",
      sorter: (a, b) => a?.ItemNo - b?.ItemNo,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="ItemNo"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "ItemNo",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Order",
      dataIndex: "OrderName",
      key: "OrderName",
      sorter: (a, b) => a?.OrderName?.length - b?.OrderName?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="OrderName"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "OrderName",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Order Time",
      dataIndex: "OrderTime",
      key: "OrderTime",
      render: (text) => <div>{text ? formatUnixTime(text) : ""}</div>,
      sorter: (a, b) => a?.OrderTime?.length - b?.OrderTime?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="OrderTime"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "OrderTime",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "License",
      dataIndex: "LicenseName",
      key: "LicenseName",
      sorter: (a, b) => a?.LicenseName?.length - b?.LicenseName?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="LicenseName"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "LicenseName",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Used",
      dataIndex: "Used",
      key: "Used",
      sorter: (a, b) => a?.Used?.length - b?.Used?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="Used"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "Used",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Used Mac",
      dataIndex: "UsedMac",
      key: "UsedMac",
      sorter: (a, b) => a?.UsedMac?.length - b?.UsedMac?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="UsedMac"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "UsedMac",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Used Time",
      dataIndex: "UsedTime",
      key: "UsedTime",
      render: (text) => <div>{text ? formatUnixTime(text) : ""}</div>,
      sorter: (a, b) => a.UsedTime?.length - b.UsedTime?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="UsedTime"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "UsedTime",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
  ];
};
