import React from "react";
const KeyIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      stroke="#B3B3B3"
      d="M4.824 14a2.824 2.824 0 1 0 0-5.647 2.824 2.824 0 0 0 0 5.647Z"
    />
    <path
      stroke="#B3B3B3"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m9.765 5.176 2.294 2.118m-5.47 1.412 6-6.706m-.707 1.412 2.47 2.294"
    />
  </svg>
);
export default KeyIcon;
