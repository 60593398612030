import { Table } from "antd";
import "../styles/globalTable.css";

export const GlobalTable = ({
  columns,
  dataSource,
  pagination,
  rowSelection,
  onRow,
}) => {
  return (
    <div className="my-table">
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={pagination}
        rowSelection={rowSelection}
        onRow={onRow}
        showSorterTooltip={false}
      />
    </div>
  );
};
