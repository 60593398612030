import React from "react";
const CameraIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill="#CCC"
      fillRule="evenodd"
      d="M6.832 3.74a2.346 2.346 0 0 1 2.306-1.865h2.724c1.112 0 2.083.772 2.306 1.864a.547.547 0 0 0 .517.437h.027c1.17.051 2.068.195 2.818.687.473.31.88.709 1.196 1.174.394.579.567 1.244.65 2.047.082.786.082 1.77.082 3.017v.07c0 1.247 0 2.232-.081 3.017-.084.804-.257 1.469-.651 2.048a4.333 4.333 0 0 1-1.196 1.174c-.586.384-1.258.554-2.073.635-.799.08-1.8.08-3.071.08H8.614c-1.272 0-2.272 0-3.07-.08-.816-.08-1.488-.25-2.074-.635a4.333 4.333 0 0 1-1.196-1.175c-.394-.578-.567-1.243-.65-2.047-.082-.785-.082-1.77-.082-3.016V11.1c0-1.247 0-2.231.081-3.017.084-.803.257-1.468.651-2.046A4.333 4.333 0 0 1 3.47 4.861c.75-.491 1.648-.635 2.817-.685l.015-.001h.013a.547.547 0 0 0 .517-.437Zm2.306-.615c-.533 0-.98.37-1.081.863-.163.8-.873 1.43-1.727 1.438-1.123.05-1.725.187-2.175.482-.334.22-.622.503-.848.833-.23.337-.368.77-.441 1.472-.074.714-.074 1.634-.074 2.924s0 2.209.075 2.922c.072.703.21 1.135.441 1.474.224.328.511.611.848.832.347.228.792.365 1.511.437.729.072 1.667.073 2.981.073h3.704c1.313 0 2.251 0 2.98-.073.72-.072 1.165-.209 1.512-.437.337-.22.625-.504.848-.833.23-.338.369-.77.442-1.473.073-.713.074-1.633.074-2.922 0-1.29 0-2.21-.075-2.924-.072-.702-.21-1.135-.441-1.472a3.083 3.083 0 0 0-.848-.833c-.448-.295-1.05-.432-2.175-.482a1.797 1.797 0 0 1-1.726-1.438 1.095 1.095 0 0 0-1.081-.863H9.138ZM10.5 8.958a1.875 1.875 0 1 0 0 3.75 1.875 1.875 0 0 0 0-3.75Zm-3.125 1.875a3.125 3.125 0 1 1 6.25 0 3.125 3.125 0 0 1-6.25 0Zm7.5-2.5a.625.625 0 0 1 .625-.625h.833a.625.625 0 1 1 0 1.25H15.5a.625.625 0 0 1-.625-.625Z"
      clipRule="evenodd"
    />
  </svg>
);
export default CameraIcon;
