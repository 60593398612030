import React from "react";
import { Button } from "antd";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CommonButton = ({ buttonsData }) => {
  return (
    <div className="mt-2">
      {buttonsData.map((button, index) => (
        <Button
          key={index}
          type="primary"
          //   icon={<FontAwesomeIcon icon={button.icon} />}
          onClick={button.onClick}
          disabled={button.disabled}
          className="mr-2 min-w-[100px] h-[30px] py-1 px-[10px] font-normal"
        >
          {button.icon}
          {button.label}
        </Button>
      ))}
    </div>
  );
};

export default CommonButton;
