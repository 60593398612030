import React from "react";
const UserIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      stroke="#B3B3B3"
      d="M8 6.667a2.667 2.667 0 1 0 0-5.334 2.667 2.667 0 0 0 0 5.334ZM13.333 11.667c0 1.656 0 3-5.333 3s-5.333-1.344-5.333-3c0-1.657 2.388-3 5.333-3 2.945 0 5.333 1.343 5.333 3Z"
    />
  </svg>
);
export default UserIcon;
