import React from "react";
const ChannelIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill="#CCC"
      d="M3.5 5.5A2.5 2.5 0 0 1 6 3h5a2.5 2.5 0 0 1 2.5 2.5.5.5 0 0 1-1 0A1.5 1.5 0 0 0 11 4H6a1.5 1.5 0 0 0-1.5 1.5v5A1.5 1.5 0 0 0 6 12h4.514a2.25 2.25 0 1 1 .114 1H6a2.5 2.5 0 0 1-2.5-2.5v-5Zm8 6.75a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0ZM8 14a.5.5 0 0 0-.5.5A2.5 2.5 0 0 0 10 17h5a2.5 2.5 0 0 0 2.5-2.5v-5A2.5 2.5 0 0 0 15 7h-4.628a2.25 2.25 0 1 0 .114 1H15a1.5 1.5 0 0 1 1.5 1.5v5A1.5 1.5 0 0 1 15 16h-5a1.5 1.5 0 0 1-1.5-1.5.5.5 0 0 0-.5-.5Zm.25-7.5a1.25 1.25 0 1 1 0 2.5 1.25 1.25 0 0 1 0-2.5Z"
    />
  </svg>
);
export default ChannelIcon;
