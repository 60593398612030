import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Input, Button, Typography } from "antd";
import { faCheckCircle, faWarning } from "@fortawesome/free-solid-svg-icons";
import logo from "../../assets/images/logo/mainLogo.png";
import usePost from "../hooks/usePost";
import { showToast } from "../utils/toast";
import AuthLayout from "./AuthLayout";
import usePostLogin from "../hooks/usePostLogin";
import EmailIcon from "../../assets/images/vectors/EmailIcon";
import useOemData from "../hooks/useOemData";

export const ForgotPassword = () => {
  const { oemNo, oemData, loading: oemDataLoading } = useOemData();
  const postUrl = `${process.env.REACT_APP_API_URL}/api/sendemail`;
  const {
    data: postData,
    loading: postLoading,
    error: postError,
    postData: postAction,
  } = usePostLogin(postUrl);

  console.log({ postData });

  const navigate = useNavigate();

  useEffect(() => {
    if (postData) {
      showToast("Successfully sent the code!", "success", faCheckCircle);
      navigate("/reset-password");
    }
    if (postError) {
      showToast(
        "Login failed. Please check your credentials and try again.",
        "error",
        faWarning
      );
    }
  }, [postData, postError, postLoading]);

  // on submit handler.
  const onSubmitHandler = async (values) => {
    try {
      await postAction(values);
    } catch (error) {
      showToast(
        "Something went wrong. Please check your email and try again.",
        "error",
        faWarning
      );
    }
  };

  return (
    <AuthLayout>
      <div className={`w-[336px]`}>
        <img
          src={require(`../../oem/${oemNo}/images/mainLogo.png`)}
          alt="Sicunet"
          className="w-[170px] mt-16 mb-6 mx-auto"
        />
        <p className="text-sm mb-8">
          Please enter the email associated with your account.
        </p>
        <Form
          layout={"vertical"}
          size={"middle"}
          autoComplete={"off"}
          validateTrigger={"onChange"}
          requiredMark={false}
          onFinish={onSubmitHandler}
        >
          <Form.Item
            label="Email"
            name="Email"
            className="my-input"
            rules={[
              {
                required: true,
                message: "Please input your email!",
              },
            ]}
          >
            <Input
              className="h-[40px]  !bg-white"
              placeholder=" Enter email"
              prefix={<EmailIcon />}
            />
          </Form.Item>
          <div className="flex justify-center">
            <Form.Item className="mr-2">
              <Button
                htmlType="submit"
                className="min-w-[100px] bg-customColor text-white hover:!bg-white border-none hover:!text-customColor font-medium text-[14px] !rounded !py-4 h-[35px] "
              >
                Send
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                htmlType="submit"
                className="min-w-[100px] bg-[#E9E9E9] text-customColor hover:!bg-customColor hover:!text-white border-none font-medium text-[14px] !rounded !py-4 h-[35px]"
                onClick={() => navigate("/")}
              >
                Cancel
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </AuthLayout>
  );
};
