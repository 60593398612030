import { useState } from "react";
import axios from "axios";

const useDelete = (url) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const token = JSON.parse(sessionStorage.getItem("userData"));
  const access_token = token ? token.access_token : null;

  const deleteData = async () => {
    setError(null);
    try {
      setLoading(true);
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });
      setData(response?.data);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const deleteMultiple = async (body) => {
    try {
      setLoading(true);
      const response = await axios.post(url, body, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });
      console.log({ response });
      setData(response?.data);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  return { data, loading, error, deleteData, deleteMultiple };
};

export default useDelete;
