import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import SortIcon from "../../../global/components/SortIcon";

export const LicenseColumns = (
  hoveredRow,
  setShowModal,
  setSelectedRowKeys,
  setParams,
  params
) => {
  return [
    {
      title: "No",
      dataIndex: "LicenseNo",
      key: "LicenseNo",
      sorter: (a, b) => a?.LicenseNo - b?.LicenseNo,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="LicenseNo"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "LicenseNo",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Name",
      dataIndex: "LicenseName",
      key: "LicenseName",
      sorter: (a, b) => a?.LicenseName?.length - b?.LicenseName?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="LicenseName"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "LicenseName",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Description",
      dataIndex: "LicenseDesc",
      key: "LicenseDesc",
      sorter: (a, b) => a?.LicenseDesc?.length - b?.LicenseDesc?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="LicenseDesc"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "LicenseDesc",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "License Type",
      dataIndex: "LicenseType",
      key: "LicenseType",
      sorter: (a, b) => a?.LicenseType?.length - b?.LicenseType?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="LicenseType"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "LicenseType",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Product",
      dataIndex: "Product",
      key: "Product",
      sorter: (a, b) => a?.Product?.length - b?.Product?.length,
      render: (text, record) => {
        return record.LicenseType === "Upgrade License" ? "N/A" : text;
      },
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="Product"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "Product",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Old Model",
      dataIndex: "OldModel",
      key: "OldModel",
      sorter: (a, b) => a?.OldModel?.length - b?.OldModel?.length,
      render: (text, record) => {
        return record.LicenseType !== "Upgrade License" ? "N/A" : text;
      },
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="OldModel"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "OldModel",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Model",
      dataIndex: "Model",
      key: "Model",
      sorter: (a, b) => a?.Model?.length - b?.Model?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="Model"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "Model",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Old Type",
      dataIndex: "OldType",
      key: "OldType",
      sorter: (a, b) => a?.OldType?.length - b?.OldType?.length,
      render: (text, record) => {
        return record.LicenseType !== "Upgrade License" ? "N/A" : text;
      },
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="OldType"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "OldType",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Type",
      dataIndex: "Type",
      key: "Type",
      sorter: (a, b) => a?.Type?.length - b?.Type?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="Type"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "Type",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "OEM",
      dataIndex: "OemNo",
      key: "OemNo",
      sorter: (a, b) => a?.OemNo?.length - b?.OemNo?.length,
      render: (text, record) => {
        return record.LicenseType === "Upgrade License" ? "N/A" : text;
      },
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="OemNo"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "OemNo",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Price",
      dataIndex: "Price",
      key: "Price",
      sorter: (a, b) => a?.Price?.length - b?.Price?.length,
      render: (text) => {
        return <p>{text == 0 ? "N/A" : text}</p>;
      },
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="Price"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "Price",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Action",
      fixed: "right",
      width: 100,
      align: "center",
      render: (text, record, rowIndex) => (
        <div className="flex justify-center items-center">
          <div className="mr-1">
            <Link
              to={`/license/edit/${record?.LicenseNo}`}
              onClick={(e) => e.stopPropagation()}
              className="text-[#50565E] hover:bg-gray-200 !text-[#50565E] hover:text-gray-700  p-1 rounded-[4px]"
            >
              <EditOutlined />
            </Link>
          </div>
          <div
            onClick={(e) => {
              e.stopPropagation();
              setShowModal(true);
              setSelectedRowKeys([record?.LicenseNo]);
            }}
            className="hover:cursor-pointer"
          >
            <DeleteOutlined className="text-[#50565E] hover:bg-gray-200 p-1 rounded-[4px]" />
          </div>
        </div>
      ),
    },
  ];
};
