import React from "react";
const ArrowLeftIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={12}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M5.178 11.5.228 6.55a.778.778 0 0 1 0-1.1L5.178.5a.778.778 0 0 1 1.1 1.1L2.655 5.222h10.566a.778.778 0 1 1 0 1.556H2.656L6.277 10.4a.778.778 0 1 1-1.1 1.1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default ArrowLeftIcon;
