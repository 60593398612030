import React from "react";
const WeatherIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={33}
    height={33}
    fill="none"
    {...props}
  >
    <path
      fill="#E6E6E6"
      d="M20.53 17.833h2v3.76l3.254 1.88-1 1.734-4.253-2.454v-4.92Zm4.174-4.893c-1.2-3.36-4.4-5.773-8.173-5.773-3.6 0-6.667 2.2-8 5.333a6.669 6.669 0 0 0-6.667 6.667 6.67 6.67 0 0 0 6.667 6.666h4.906c1.507 3.147 4.707 5.334 8.427 5.334a9.326 9.326 0 0 0 9.333-9.334c0-4.16-2.72-7.68-6.493-8.893ZM8.531 23.167c-2.214 0-4-1.787-4-4 0-2.214 1.786-4 4-4 .493 0 .973.093 1.413.253a6.673 6.673 0 0 1 6.587-5.587c2.173 0 4.093 1.054 5.333 2.667a9.326 9.326 0 0 0-9.333 9.333c0 .454.04.894.106 1.334H8.531ZM21.864 28.5a6.67 6.67 0 0 1-6.667-6.667 6.67 6.67 0 0 1 6.667-6.666 6.67 6.67 0 0 1 6.667 6.666 6.67 6.67 0 0 1-6.667 6.667Z"
    />
  </svg>
);
export default WeatherIcon;
