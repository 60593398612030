import React from "react";
const ModelIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill="#CCC"
      d="M16.646 7.083a2.709 2.709 0 1 1-2.636 3.334h-2.5a2.71 2.71 0 0 1-3.702 2.09L6.511 14.8a2.709 2.709 0 1 1-1.119-.562l1.394-2.464A2.7 2.7 0 0 1 6.125 10c0-.756.31-1.44.808-1.93L5.598 5.705a2.711 2.711 0 0 1-3.214-3.86 2.708 2.708 0 1 1 4.284 3.213l1.335 2.364a2.708 2.708 0 0 1 3.408 1.745h2.599a2.709 2.709 0 0 1 2.636-2.084ZM8.833 8.542a1.459 1.459 0 1 0-.07 2.916 1.459 1.459 0 0 0 .07-2.916ZM4.771 1.667a1.458 1.458 0 1 0 0 2.916 1.458 1.458 0 0 0 0-2.916Zm10.416 8.125a1.458 1.458 0 1 0 2.917 0 1.458 1.458 0 0 0-2.916 0ZM3.313 16.875a1.459 1.459 0 1 0 2.917 0 1.459 1.459 0 0 0-2.918 0Z"
    />
  </svg>
);
export default ModelIcon;
