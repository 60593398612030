import { faCheckCircle, faWarning } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { Form, Button, Col, Row } from "antd";
import useGet from "../../../global/hooks/useGet";
import usePost from "../../../global/hooks/usePost";
import usePut from "../../../global/hooks/usePut";
import CustomInput from "../../../global/components/inputWidgets/CustomInput";
import CustomSelect from "../../../global/components/inputWidgets/CustomSelect";
import { useNavigate, useParams } from "react-router-dom";
import { showToast } from "../../../global/utils/toast";
import CommonBreadcrumb from "../../../global/components/CommonBreadcrumb";
import CommonButton from "../../../global/components/CommonButton";
import { getModifiedResponse } from "../../../global/utils/responseModifier";
import {
  CheckCircleOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  PieChartOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import ConfirmModal from "../../../global/components/ConfirmModal";
import useDelete from "../../../global/hooks/useDelete";

export const OemForm = ({ type }) => {
  const [nextOemNo, setNextOemNo] = useState();
  const [licenseOp, setLicenseOp] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [form] = Form.useForm();
  const { id } = useParams();
  const navigate = useNavigate();

  const url = id && `${process.env.REACT_APP_API_URL}/api/oem/${id}`;
  const listUrl = `${process.env.REACT_APP_API_URL}/api/oem/all?limit=10&order=desc`;
  const licenseUrl = `${process.env.REACT_APP_API_URL}/api/license/all`;
  const oemOptionUrl = `${process.env.REACT_APP_API_URL}/api/oem/getoptions`;
  const postUrl = `${process.env.REACT_APP_API_URL}/api/oem/create`;
  const putUrl = `${process.env.REACT_APP_API_URL}/api/oem/update/${id}`;
  const deleteUrl = `${process.env.REACT_APP_API_URL}/api/oem/delete/${id}`;

  const { data, loading, error, refetch } = useGet(url);
  const {
    data: listData,
    loading: listDataLoading,
    error: listDataError,
  } = useGet(listUrl);

  const {
    data: OemOpData,
    loading: OemOpDataLoading,
    error: OemOpDataError,
  } = useGet(oemOptionUrl);

  const modifiedOemInfos = getModifiedResponse(OemOpData, "oeminfos");
  const {
    data: licenseData,
    loading: licenseDataLoading,
    error: licenseDataError,
  } = useGet(licenseUrl);
  const {
    data: postData,
    loading: postLoading,
    error: postError,
    postData: postAction,
  } = usePost(postUrl);
  const {
    data: putData,
    loading: putLoading,
    error: putError,
    putData: putAction,
  } = usePut(putUrl);
  const {
    data: deletedData,
    loading: deletedDataLoading,
    error: deletedDataError,
    deleteData,
  } = useDelete(deleteUrl);

  const onFinish = async (values) => {
    try {
      if (id) {
        await putAction(values);
      } else {
        await postAction(values);
      }
    } catch (error) {
      showToast("Something went wrong, Please try again!", "error", faWarning);
    }
  };

  const onReset = () => {
    // form.resetFields();
    navigate("/oemlist");
  };

  const onDeleteConfirm = async () => {
    try {
      await deleteData();
    } catch (error) {
      showToast("Failed!", "error", faWarning);
    }
    setShowModal(false);
  };

  //toat for create
  useEffect(() => {
    if (postError) {
      showToast(postError?.response?.data?.message, "error", faWarning);
    } else if (!postLoading && !postError && postData) {
      showToast(postData?.message, "success", faCheckCircle);
      setTimeout(() => {
        navigate(-1);
      }, 200);
    }
  }, [postError, postData, postLoading]);

  //toat for update
  useEffect(() => {
    if (putError) {
      showToast(putError?.response?.data?.message, "error", faWarning);
    } else if (!putLoading && !putError && putData) {
      showToast(putData?.message, "success", faCheckCircle);
      refetch();
      setTimeout(() => {
        navigate(-1);
      }, 200);
    }
  }, [putData, putLoading, putError]);

  useEffect(() => {
    const nextOem = Number(listData?.data[0]?.OemNo);
    setNextOemNo(nextOem + 1);
  }, [listDataLoading]);

  // Set default field value
  useEffect(() => {
    if (!id) {
      form.setFieldsValue({
        OemNo: String(nextOemNo),
        LicenseNo: licenseOp[0]?.value,
        OemInfoNo: modifiedOemInfos[0]?.value,
      });
    }
  }, [nextOemNo, licenseOp, modifiedOemInfos]);

  useEffect(() => {
    if (!id || loading) return;
    const getOemInfoById = () => {
      try {
        if (data?.data) {
          form.setFieldsValue({
            OemNo: String(data?.data?.OemNo) ?? id,
            OemName: data.data.OemName,
            OemDesc: data.data.OemDesc,
            Domain: data.data.Domain,
            LicenseNo: data.data.LicenseNo,
            OemInfoNo: data.data.OemInfoNo,
          });
        } else {
          console.warn("No data found for the given ID");
        }
      } catch (error) {
        if (!loading) {
          showToast("Error fetching data!", "error", faWarning);
        }
      }
    };

    getOemInfoById();
  }, [id, data, loading, form]);

  useEffect(() => {
    if (!licenseDataLoading && licenseData) {
      function filterInitialLicenses(data) {
        return data
          .filter((item) => item?.LicenseType === "Initial License")
          .map((item) => ({
            value: item?.LicenseNo,
            label: item?.LicenseName,
          }));
      }

      const filteredLicenses = filterInitialLicenses(licenseData?.data);
      setLicenseOp(filteredLicenses);
    }
  }, [licenseDataLoading, licenseData]);

  useEffect(() => {
    if (deletedData && !deletedDataError) {
      showToast(deletedData?.message, "success", faCheckCircle);
      refetch();
      navigate("/oemlist");
    } else if (deletedDataError) {
      showToast(deletedDataError?.response?.data?.message, "error", faWarning);
    }
  }, [deletedData, deletedDataError]);

  const breadcrumbItems = [
    {
      label: "OEM",
      href: "/oemlist",
    },
    {
      label: id ? (type === "info" ? "Information" : "Edit") : "Add",
      href: id
        ? type === "info"
          ? `/oem/info/${id}`
          : `/oem/edit/${id}`
        : "/oem/add",
    },
  ];

  const buttonsData =
    type === "info"
      ? [
          {
            label: "Edit",
            icon: <EditOutlined />,
            onClick: () => {
              navigate(`/oem/edit/${id}`);
            },
          },
          {
            label: "Delete",
            icon: <DeleteOutlined />,
            onClick: () => {
              setShowModal(true);
            },
          },
          {
            label: "List",
            icon: <UnorderedListOutlined />,
            onClick: () => {
              navigate("/oemlist");
            },
          },
        ]
      : [
          {
            label: "Apply",
            icon: <CheckCircleOutlined />,
            onClick: () => form.submit(),
          },
          {
            label: "Cancel",
            icon: <CloseOutlined />,
            onClick: onReset,
          },
        ];

  return (
    <div>
      <div className="flex justify-between">
        <CommonBreadcrumb breadcrumbItems={breadcrumbItems} />
        <CommonButton buttonsData={buttonsData} />
      </div>
      <div className="mt-6 rounded-[4px] overflow-hidden">
        {
          <Form
            validateTrigger={"onChange"}
            form={form}
            onFinish={onFinish}
            scrollToFirstError
            layout={"vertical"}
            autoComplete={"off"}
          >
            <div className="bg-gray-100 p-2">
              <PieChartOutlined />
              <span className="text-md ml-2">OEM</span>
            </div>
            <div className="p-2">
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomInput
                    label="OEM No"
                    name="OemNo"
                    disabled={type === "info" || type === "edit"}
                    type="number"
                    required
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomInput
                    label="OEM Name"
                    name="OemName"
                    required
                    disabled={type === "info"}
                  />
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomInput
                    label="Description"
                    name="OemDesc"
                    disabled={type === "info"}
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomInput
                    label="Domain"
                    name="Domain"
                    disabled={type === "info"}
                  />
                </Col>
              </Row>
              <Row gutter={[16, 16]} className="mt-2">
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomSelect
                    label="License"
                    name="LicenseNo"
                    options={licenseOp}
                    placeholder="Please select"
                    disabled={type === "info"}
                    required
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomSelect
                    label="OEM Info"
                    name="OemInfoNo"
                    options={modifiedOemInfos}
                    placeholder="Please select"
                    disabled={type === "info"}
                    required
                  />
                </Col>
              </Row>
            </div>
          </Form>
        }
      </div>
      <ConfirmModal
        showModal={showModal}
        setShowModal={setShowModal}
        onDeleteConfirm={onDeleteConfirm}
      />
    </div>
  );
};
