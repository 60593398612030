import React from "react";
import { Avatar, Dropdown, Menu } from "antd";
import { UserOutlined, LogoutOutlined } from "@ant-design/icons";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { showToast } from "../utils/toast";

export const User = () => {
  const navigate = useNavigate();
  const userData = JSON.parse(sessionStorage.getItem("userData"));

  const handleMenuClick = (e) => {
    if (e.key === "profile") {
      navigate("/profile");
    } else if (e.key === "logout") {
      sessionStorage.removeItem("userData");
      showToast("Logged out successfully !", "success", faCheckCircle);
      navigate("/");
    }
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      {userData?.role_name != "Customer" && (
        <Menu.Item key="profile" icon={<UserOutlined />}>
          Profile
        </Menu.Item>
      )}
      <Menu.Item key="logout" icon={<LogoutOutlined />}>
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      <Avatar
        size="large"
        icon={<UserOutlined />}
        className="h-[32px] w-[32px] cursor-pointer"
      />
    </Dropdown>
  );
};
