import React from "react";
const SubnodeIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#CCC"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M12.583 16.25h-.833c-2.357 0-3.536 0-4.268-.732-.732-.732-.732-1.911-.732-4.268V9.583m0 0V6.667m0 2.916h3.75"
    />
    <path
      stroke="#CCC"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M15.083 7.5v4.167m2.084-2.084H13m-.417 6.667c0-.982 0-1.473.293-1.778.294-.305.766-.305 1.707-.305h1c.943 0 1.414 0 1.707.305.293.305.293.796.293 1.778s0 1.473-.293 1.778c-.292.305-.764.305-1.707.305h-1c-.942 0-1.414 0-1.706-.305-.294-.305-.294-.796-.294-1.778ZM5.322 1.667h2.856c1.73 0 1.905.925 1.905 2.5s-.176 2.5-1.905 2.5H5.322c-1.73 0-1.905-.925-1.905-2.5s.175-2.5 1.905-2.5Z"
    />
  </svg>
);
export default SubnodeIcon;
