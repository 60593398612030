export const getModifiedResponse = (response, keyName) => {
  if (response && response.data && response.data[keyName]) {
    return response.data[keyName].map((item) => ({
      label: item.name,
      value: item.value,
    }));
  } else {
    return [];
  }
};
