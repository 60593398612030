import React from "react";
import { Form, Checkbox } from "antd";

const CustomCheckbox = ({
  label,
  name,
  options,
  defaultValue,
  errorMessage,
  required = false,
  onChange,
  ...rest
}) => {
  return (
    <Form.Item
      label={label}
      className={"mb-2"}
      name={name}
      initialValue={defaultValue}
      rules={[{ required: required, message: `${label} is required` }]}
      validateStatus={errorMessage ? "error" : ""}
      help={errorMessage}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <Checkbox.Group options={options} onChange={onChange} {...rest} />
    </Form.Item>
  );
};

export default CustomCheckbox;
