import React from "react";
import { Form, Input } from "antd";

const { TextArea } = Input;

const CustomTextArea = ({
  label,
  name,
  defaultValue,
  errorMessage,
  required,
  placeholder,
  rows,
  maxLength,
  onChange,
  ...rest
}) => {
  return (
    <Form.Item
      label={
        required ? (
          <span>
            {label} <span style={{ color: "red" }}>*</span>
          </span>
        ) : (
          label
        )
      }
      className={"mb-2"}
      name={name}
      initialValue={defaultValue}
      rules={[{ required: required, message: `${label} is required` }]}
      validateStatus={errorMessage ? "error" : ""}
      help={errorMessage}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      required={false}
    >
      <TextArea
        placeholder={placeholder ?? `Enter ${label}`}
        rows={rows}
        maxLength={maxLength}
        onChange={onChange}
        {...rest}
      />
    </Form.Item>
  );
};

export default CustomTextArea;
