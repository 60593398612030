import { toast } from "react-toastify";

export const showToast = (message, type = "default", icon = null) => {
  console.log("toast");
  const toastOptions = {
    icon,
  };

  switch (type) {
    case "success":
      toast.success(message, toastOptions);
      break;
    case "error":
      toast.error(message, toastOptions);
      break;
    case "info":
      toast.info(message, toastOptions);
      break;
    case "warning":
      toast.warn(message, toastOptions);
      break;
    default:
      toast(message, toastOptions);
  }
};
