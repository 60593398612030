import React from "react";
const TypeIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill="#CCC"
      fillRule="evenodd"
      d="M3.167 1.333a1.333 1.333 0 0 0-1.334 1.334v14.666a1.333 1.333 0 0 0 1.334 1.334h14.666a1.334 1.334 0 0 0 1.334-1.334V2.667a1.334 1.334 0 0 0-1.334-1.334H3.167Zm0 1.334h14.666v14.666H3.167V2.667ZM6.5 5.333A.667.667 0 0 0 5.833 6v8a.666.666 0 0 0 .667.667h8a.666.666 0 0 0 .667-.667V6a.666.666 0 0 0-.667-.667h-8Zm.667 8V6.667h6.666v6.666H7.167Z"
      clipRule="evenodd"
    />
  </svg>
);
export default TypeIcon;
