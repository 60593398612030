import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import SortIcon from "../../../global/components/SortIcon";

export const OemColumns = (
  hoveredRow,
  setShowModal,
  setSelectedRowKeys,
  setParams,
  params
) => {
  return [
    {
      title: "No",
      dataIndex: "OemNo",
      key: "OemNo",
      sorter: (a, b) => a?.OemNo - b?.OemNo,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="OemNo"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "OemNo",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Name",
      dataIndex: "OemName",
      key: "OemName",
      sorter: (a, b) => a?.OemName?.length - b?.OemName?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="OemName"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "OemName",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Description",
      dataIndex: "OemDesc",
      key: "OemDesc",
      sorter: (a, b) => a?.OemDesc?.length - b?.OemDesc?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="OemDesc"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "OemDesc",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Domain",
      dataIndex: "Domain",
      key: "Domain",
      sorter: (a, b) => a?.Domain?.length - b?.Domain?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="Domain"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "Domain",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "License",
      dataIndex: "LicenseName",
      key: "LicenseName",
      sorter: (a, b) => a?.LicenseName?.length - b?.LicenseName?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="LicenseName"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "LicenseName",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "OEM Info",
      dataIndex: "OemInfoNo",
      key: "OemInfoNo",
      sorter: (a, b) => a?.OemInfoNo?.length - b?.OemInfoNo?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="OemInfoNo"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "OemInfoNo",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Action",
      fixed: "right",
      width: 100,
      align: "center",
      render: (text, record, rowIndex) => (
        <div className="flex justify-center items-center">
          <div className="mr-1">
            <Link
              to={`/oem/edit/${record?.OemNo}`}
              onClick={(e) => e.stopPropagation()}
              className="text-[#50565E] hover:bg-gray-200 !text-[#50565E] hover:text-gray-700  p-1 rounded-[4px]"
            >
              <EditOutlined />
            </Link>
          </div>
          <div
            onClick={(e) => {
              e.stopPropagation();
              setShowModal(true);
              setSelectedRowKeys([record?.OemNo]);
            }}
            className="hover:cursor-pointer"
          >
            <DeleteOutlined className="text-[#50565E] hover:bg-gray-200 p-1 rounded-[4px]" />
          </div>
        </div>
      ),
    },
  ];
};
