import { Oem } from "./modules/Oem/Page/Oem";
import { OemForm } from "./modules/Oem/Components/OemForm";
import { Account } from "./modules/Account/Page/Account";
import { Device } from "./modules/Device/Page/Device";
import { DeviceLog } from "./modules/DeviceLog/Page/DeviceLog";
import { Order } from "./modules/Order/Page/Order";
import { OrderItem } from "./modules/OrderItem/Page/OrderItem";
import { License } from "./modules/License/Page/License";
import { LicenseOption } from "./modules/LicenseOption/Page/LicenseOption";
import { OemInfo } from "./modules/OemInfo/Page/OemInfo";
import { DeviceForm } from "./modules/Device/Components/DeviceForm";
import { AddByListForm } from "./modules/Device/Components/AddByListForm";
import { AddByCountForm } from "./modules/Device/Components/AddByCountForm";
import { AccountForm } from "./modules/Account/Components/AccountForm";
import { OrderForm } from "./modules/Order/Components/OrderForm";
import { OrderInfoForm } from "./modules/Order/Components/OrderInfoForm";
import { OrderItemForm } from "./modules/OrderItem/Components/OrderItemForm";
import { LicenseForm } from "./modules/License/Components/LicenseForm";
import { LicenseOptionForm } from "./modules/LicenseOption/Components/LicenseOptionForm";
import { OemInfoForm } from "./modules/OemInfo/Components/OemInfoForm";
import { Dealer } from "./modules/Dealer/Page/Dealer";
import { DealerForm } from "./modules/Dealer/Components/DealerForm";
import { ProfileForm } from "./global/components/ProfileForm";
import { OrderItemAddForm } from "./modules/Order/Components/OrderItemAdd";
import { DeviceLogInfo } from "./modules/DeviceLog/Components/DeviceLogInfo";
import { OrderItemInfoForm } from "./modules/Order/Components/OrderItemInfoForm";

export const protectedRoutes = [
  { path: "/profile", element: <ProfileForm /> },
  { path: "/oemlist", element: <Oem /> },
  { path: "/oem/add", element: <OemForm /> },
  { path: "/oem/edit/:id", element: <OemForm type="edit" /> },
  { path: "/oem/info/:id", element: <OemForm type="info" /> },

  { path: "/devicelist", element: <Device /> },
  { path: "/devicelist/add", element: <DeviceForm /> },
  { path: "/device/edit/:id", element: <DeviceForm type="edit" /> },
  { path: "/device/info/:id", element: <DeviceForm type="info" /> },
  { path: "/addDeviceByList", element: <AddByListForm /> },
  { path: "/addDeviceByCount", element: <AddByCountForm /> },

  { path: "/accountlist", element: <Account /> },
  { path: "/account/add", element: <AccountForm /> },
  { path: "/account/edit/:id", element: <AccountForm type="edit" /> },
  { path: "/account/info/:id", element: <AccountForm type="info" /> },

  { path: "/deviceloglist", element: <DeviceLog /> },
  { path: "/devicelog/info/:id", element: <DeviceLogInfo /> },
  { path: "/orderlist", element: <Order /> },
  { path: "/order/add", element: <OrderForm /> },
  { path: "/orderedit/:id", element: <OrderForm /> },
  { path: "/orderinfo/edit/:id", element: <OrderInfoForm type="edit" /> },
  { path: "/orderinfo/info/:id", element: <OrderInfoForm type="info" /> },

  { path: "/orderitemlist", element: <OrderItem /> },
  { path: "/orderitem/add/:id", element: <OrderItemAddForm /> },
  { path: "/orderiteminfo/:id", element: <OrderItemForm /> },
  { path: "/orderitem/info/:id", element: <OrderItemInfoForm type="info" /> },
  { path: "/orderitem/edit/:id", element: <OrderItemInfoForm type="edit" /> },

  { path: "/licenselist", element: <License /> },
  { path: "/license/add", element: <LicenseForm /> },
  { path: "/license/edit/:id", element: <LicenseForm type="edit" /> },
  { path: "/license/info/:id", element: <LicenseForm type="info" /> },

  { path: "/licenseoptionlist", element: <LicenseOption /> },
  { path: "/licenseoption/add", element: <LicenseOptionForm /> },
  {
    path: "/licenseoption/edit/:id",
    element: <LicenseOptionForm type="edit" />,
  },
  {
    path: "/licenseoption/info/:id",
    element: <LicenseOptionForm type="info" />,
  },

  { path: "/oeminfolist", element: <OemInfo /> },
  { path: "/oeminfo/add", element: <OemInfoForm /> },
  { path: "/oeminfo/edit/:id", element: <OemInfoForm type="edit" /> },
  { path: "/oeminfo/info/:id", element: <OemInfoForm type="info" /> },

  { path: "/dealerlist", element: <Dealer /> },
  { path: "/dealer/add", element: <DealerForm /> },
  { path: "/dealer/edit/:id", element: <DealerForm type="edit" /> },
  { path: "/dealer/info/:id", element: <DealerForm type="info" /> },
];
