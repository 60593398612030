import React from "react";
import { Select, Form } from "antd";
import "../../styles/customSelect.css";

const CustomSelect = ({
  label,
  placeholder,
  name,
  options,
  formItemProps,
  mode,
  defaultValue,
  required,
  onSearch,
  onChange,
  disabled,
  labelInValue,
}) => {
  const handleSearch = (input) => {
    if (onSearch) {
      onSearch(input);
    }
  };

  const handleChange = (value) => {
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <Form.Item
      label={
        required ? (
          <span>
            {label} <span style={{ color: "red" }}>*</span>
          </span>
        ) : (
          label
        )
      }
      name={name}
      {...formItemProps}
      rules={[{ required: required, message: `${label} is required` }]}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      style={{ marginBottom: "0.75rem" }}
      required={false}
    >
      <Select
        disabled={disabled}
        labelInValue={labelInValue}
        allowClear
        showSearch
        mode={mode}
        placeholder={placeholder}
        optionFilterProp="label"
        filterOption={(input, option) => {
          const label = String(option?.label ?? "");
          return label.toLowerCase().includes(input.toLowerCase());
        }}
        // filterSort={(optionA, optionB) => {
        //   const labelA = String(optionA?.label ?? "");
        //   const labelB = String(optionB?.label ?? "");
        //   return labelA.toLowerCase().localeCompare(labelB.toLowerCase());
        // }}
        options={options}
        defaultValue={defaultValue?.value}
        onSearch={handleSearch}
        onChange={handleChange}
      />
    </Form.Item>
  );
};

export default CustomSelect;
