import React from "react";
const ProductIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#CCC"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M2.583 7.5h15.834M6.333 5h.008m3.326 0h.007m-7.09 5c0-3.732 0-5.598 1.158-6.757 1.16-1.16 3.025-1.16 6.758-1.16 3.732 0 5.598 0 6.757 1.16 1.16 1.159 1.16 3.025 1.16 6.757 0 3.732 0 5.598-1.16 6.758-1.159 1.159-3.025 1.159-6.757 1.159-3.732 0-5.598 0-6.758-1.16C2.583 15.598 2.583 13.732 2.583 10Z"
    />
    <path
      stroke="#CCC"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m7.167 10.833 1.022.881c.43.371.644.556.644.786 0 .23-.215.415-.644.786l-1.022.88m3.333 0h3.333"
    />
  </svg>
);
export default ProductIcon;
