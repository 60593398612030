import React from "react";
const IntercomIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#CCC"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M17.167 1.667H3.833c-.92 0-1.666.746-1.666 1.666v13.334c0 .92.746 1.666 1.666 1.666h13.334c.92 0 1.666-.746 1.666-1.666V3.333c0-.92-.746-1.666-1.666-1.666ZM5.5 7.5v5M8.833 5v10m3.334-10v10M15.5 7.5v5"
    />
  </svg>
);
export default IntercomIcon;
