import React from "react";
const SicunetSmallIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={96}
    height={16}
    viewBox="0 0 96 16"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_23_2147)">
      <path
        d="M28.7431 2.21619C27.0018 2.21619 25.2645 2.21619 23.5232 2.21619C22.4705 2.82168 21.4139 3.43113 20.3612 4.04057C20.1871 5.05764 20.0169 6.07471 19.8428 7.09177C19.637 8.30276 20.571 9.40689 21.8017 9.40689C23.543 9.40689 25.2803 9.40689 27.0216 9.40689C26.9345 9.93323 26.8435 10.4556 26.7565 10.978C25.1854 10.978 23.6103 10.978 22.0392 10.978C22.0827 10.7168 22.1262 10.4556 22.1737 10.1944C21.2239 10.1944 20.2741 10.1944 19.3204 10.1944C19.2492 10.6218 19.174 11.0492 19.1027 11.4766C18.897 12.6876 19.8309 13.7917 21.0617 13.7917C23.0523 13.7917 25.0429 13.7917 27.0374 13.7917C28.2484 13.7917 29.2813 12.9172 29.4831 11.726C29.6414 10.788 29.7997 9.85408 29.962 8.91616C30.1678 7.70518 29.2338 6.60105 28.0031 6.60105C26.2618 6.60105 24.5245 6.60105 22.7832 6.60105C22.8702 6.07866 22.9613 5.55232 23.0483 5.02994C24.6194 5.02994 26.1945 5.02994 27.7656 5.02994C27.7221 5.29113 27.6785 5.55232 27.6311 5.81352C28.5808 5.81352 29.5306 5.81352 30.4844 5.81352C30.5556 5.38611 30.6308 4.9587 30.702 4.53526C30.9039 3.32032 29.9739 2.21619 28.7431 2.21619Z"
        fill="white"
      />
      <path
        d="M69.4773 2.21619C67.736 2.21619 65.9987 2.21619 64.2574 2.21619C63.2047 2.82168 62.1481 3.43113 61.0954 4.04057C60.5453 7.28965 59.9912 10.5387 59.4412 13.7878C60.391 13.7878 61.3407 13.7878 62.2945 13.7878C62.7892 10.8672 63.2839 7.94658 63.7825 5.02598C65.3536 5.02598 66.9287 5.02598 68.4998 5.02598C68.0051 7.94658 67.5104 10.8672 67.0157 13.7878C67.9655 13.7878 68.9153 13.7878 69.8691 13.7878C70.3914 10.701 70.9138 7.61812 71.4362 4.5313C71.638 3.32032 70.7041 2.21619 69.4773 2.21619Z"
        fill="white"
      />
      <path
        d="M32.5937 3.1264L30.7852 13.7838H32.2098L33.7928 12.8736L35.6014 2.21619H34.1727L32.5937 3.1264Z"
        fill="white"
      />
      <path
        d="M73.1696 4.0366C72.7501 6.51397 72.3306 8.99133 71.9072 11.4687C71.7014 12.6797 72.6354 13.7838 73.8661 13.7838C76.5572 13.7838 79.2483 13.7838 81.9393 13.7838C82.0976 12.8459 82.2559 11.9119 82.4142 10.974C79.8894 10.974 77.3685 10.974 74.8436 10.974C74.9426 10.3962 75.0375 9.81844 75.1365 9.2367C76.7946 9.2367 78.4528 9.2367 80.111 9.2367C80.5424 8.98738 80.9737 8.73806 81.4051 8.48874C81.504 7.91095 81.599 7.33316 81.6979 6.75537C79.648 6.75537 77.602 6.75537 75.556 6.75537C75.6549 6.17758 75.7538 5.59979 75.8488 5.01805C78.3737 5.01805 80.8946 5.01805 83.4194 5.01805C83.5777 4.08013 83.736 3.14617 83.8943 2.20825C81.3695 2.20825 78.8486 2.20825 76.3237 2.20825C75.2789 2.82166 74.2223 3.43111 73.1696 4.0366Z"
        fill="white"
      />
      <path
        d="M85.5565 2.21619L85.0776 5.02202H88.8649L87.3769 13.7838H90.2302L91.7143 5.02202H93.2696L95.743 3.59734L95.9765 2.21619H85.5565Z"
        fill="white"
      />
      <path
        d="M56.8966 2.21619C56.4019 5.13679 55.9072 8.05739 55.4125 10.978C53.8414 10.978 52.2663 10.978 50.6952 10.978C51.1899 8.05739 51.6846 5.13679 52.1793 2.21619C51.2295 2.21619 50.2797 2.21619 49.326 2.21619C48.8036 5.303 48.2812 8.38586 47.7548 11.4727C47.5491 12.6837 48.483 13.7878 49.7138 13.7878C51.4551 13.7878 53.1924 13.7878 54.9337 13.7878C55.9864 13.1783 57.039 12.5729 58.0957 11.9634C58.6458 8.71433 59.1958 5.46526 59.7499 2.21619C58.8001 2.21619 57.8503 2.21619 56.8966 2.21619Z"
        fill="white"
      />
      <path
        d="M45.3249 2.21619C43.5837 2.21619 41.8463 2.21619 40.1051 2.21619C39.0524 2.82168 37.9957 3.43113 36.943 4.04057C36.5236 6.51794 36.1041 8.99531 35.6806 11.4727C35.4748 12.6837 36.4088 13.7878 37.6396 13.7878C39.6302 13.7878 41.6247 13.7878 43.6153 13.7878C44.8263 13.7878 45.8592 12.9132 46.061 11.722C46.1916 10.9503 46.3222 10.1786 46.4528 9.40689C45.503 9.40689 44.5532 9.40689 43.5995 9.40689C43.5124 9.93323 43.4214 10.4556 43.3343 10.978C41.7632 10.978 40.1882 10.978 38.6171 10.978C38.9534 8.99531 39.2898 7.00867 39.6262 5.02598C41.1973 5.02598 42.7724 5.02598 44.3435 5.02598C44.2564 5.54837 44.1654 6.07471 44.0783 6.59709C45.0281 6.59709 45.9779 6.59709 46.9317 6.59709C47.0464 5.90849 47.1652 5.2199 47.2799 4.5313C47.4897 3.32032 46.5557 2.21619 45.3249 2.21619Z"
        fill="white"
      />
      <path
        d="M10.3806 1.5977L3.21729 13.9957L6.5104 15.8984L13.6737 3.5004L10.3806 1.5977Z"
        fill="white"
      />
      <path
        d="M7.40845 16C7.61819 15.9683 7.80815 15.9011 7.99019 15.7982C9.82645 14.7376 11.6627 13.677 13.499 12.6203C14.0095 12.3235 14.3261 11.7774 14.3261 11.1877C14.3261 9.07049 14.3261 6.94929 14.3261 4.83205C14.3261 4.61835 14.2905 4.42444 14.2113 4.22656C11.9437 8.1484 9.67607 12.0742 7.40845 16Z"
        fill="white"
      />
      <path
        d="M0 7.23425C0 8.55209 0 9.86992 0 11.1838C0 11.7735 0.316597 12.3196 0.827108 12.6164C1.38511 12.9369 1.94311 13.2615 2.50111 13.582C3.2095 12.3552 3.91788 11.1284 4.62627 9.90158C3.08286 9.01511 1.54341 8.12468 0 7.23425Z"
        fill="white"
      />
      <path
        d="M7.12342 5.58C5.44546 4.61042 3.76354 3.64084 2.08558 2.67126C1.66609 2.91663 1.2466 3.15803 0.827108 3.39944C0.316597 3.69625 0 4.23842 0 4.83204C0 5.31485 0 5.79766 0 6.28442C1.68192 7.254 3.35988 8.22358 5.03784 9.19315C5.73435 7.98613 6.43087 6.78306 7.12342 5.58Z"
        fill="white"
      />
      <path
        d="M9.66402 1.18721C9.10602 0.866657 8.54802 0.542146 7.99002 0.221592C7.4795 -0.0712598 6.85027 -0.0712598 6.3358 0.221592C5.19605 0.87853 4.05235 1.53943 2.9126 2.19636C4.45205 3.08679 5.99546 3.97722 7.53886 4.86369C8.24725 3.64084 8.95563 2.41402 9.66402 1.18721Z"
        fill="white"
      />
      <path
        d="M10.3806 1.5977L3.21729 13.9957L6.5104 15.8984L13.6737 3.5004L10.3806 1.5977Z"
        fill="white"
      />
      <path
        d="M7.40845 16C7.61819 15.9683 7.80815 15.9011 7.99019 15.7982C9.82645 14.7376 11.6627 13.677 13.499 12.6203C14.0095 12.3235 14.3261 11.7774 14.3261 11.1877C14.3261 9.07049 14.3261 6.94929 14.3261 4.83205C14.3261 4.61835 14.2905 4.42444 14.2113 4.22656C11.9437 8.1484 9.67607 12.0742 7.40845 16Z"
        fill="white"
      />
      <path
        d="M0 7.23425C0 8.55209 0 9.86992 0 11.1838C0 11.7735 0.316597 12.3196 0.827108 12.6164C1.38511 12.9369 1.94311 13.2615 2.50111 13.582C3.2095 12.3552 3.91788 11.1284 4.62627 9.90158C3.08286 9.01511 1.54341 8.12468 0 7.23425Z"
        fill="white"
      />
      <path
        d="M7.12342 5.58C5.44546 4.61042 3.76354 3.64084 2.08558 2.67126C1.66609 2.91663 1.2466 3.15803 0.827108 3.39944C0.316597 3.69625 0 4.23842 0 4.83204C0 5.31485 0 5.79766 0 6.28442C1.68192 7.254 3.35988 8.22358 5.03784 9.19315C5.73435 7.98613 6.43087 6.78306 7.12342 5.58Z"
        fill="white"
      />
      <path
        d="M9.66402 1.18721C9.10602 0.866657 8.54802 0.542146 7.99002 0.221592C7.4795 -0.0712598 6.85027 -0.0712598 6.3358 0.221592C5.19605 0.87853 4.05235 1.53943 2.9126 2.19636C4.45205 3.08679 5.99546 3.97722 7.53886 4.86369C8.24725 3.64084 8.95563 2.41402 9.66402 1.18721Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_23_2147">
        <rect width={95.9763} height={16} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default SicunetSmallIcon;
