import { useEffect, useState } from "react";
import { GlobalTable } from "../../../global/components/GlobalTable";
import { useNavigate, Link } from "react-router-dom";
import { Spinner } from "../../../global/components/Spinner";
import useGet from "../../../global/hooks/useGet";
import { OrderItemColumns } from "./Columns";
import ConfirmModal from "../../../global/components/ConfirmModal";
import useDelete from "../../../global/hooks/useDelete";
import { faCheckCircle, faWarning } from "@fortawesome/free-solid-svg-icons";
import { showToast } from "../../../global/utils/toast";
import { Button } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { OrderItemAddForm } from "../Components/OrderItemAdd";

export const OrderInfo = ({ paidStatus, id }) => {
  const [hoveredRow, setHoveredRow] = useState(null);
  const [listData, setListData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [pageA, setPageA] = useState();
  const [showForm, setShowForm] = useState(false);
  const [formType, setFormType] = useState(false);
  const [selectedOrderItemNo, setSelectedOrderItemNo] = useState();
  const logData = JSON.parse(sessionStorage.getItem("userData"));

  const [pageSizeA, setPageSizeA] = useState(8);
  //sorting, paginatio not implemented yet
  const [params, setParams] = useState({
    limit: 10,
    sort_by: "ItemNo",
    order: "desc",
  });

  // to get table data from API server
  const query = new URLSearchParams(params).toString();
  // const url = query
  //   ? `${process.env.REACT_APP_API_URL}/api/orderitems/all?${query}`
  //   : `${process.env.REACT_APP_API_URL}/api/orderitems/orderno=${id}/`;
  const url = `${process.env.REACT_APP_API_URL}/api/orderitems/orderno=${id}/`;
  const { data, loading, error, refetch } = useGet(url);

  let deleteUrl;
  selectedRowKeys.length > 1
    ? (deleteUrl = `${process.env.REACT_APP_API_URL}/api/orderitems/delete`)
    : (deleteUrl = `${process.env.REACT_APP_API_URL}/api/orderitem/delete/${selectedRowKeys}`);

  const {
    data: deletedData,
    loading: deletedDataLoading,
    error: deletedDataError,
    deleteData,
    deleteMultiple,
  } = useDelete(deleteUrl);

  const paginationConfig = {
    current: pageA,
    pageSize: pageSizeA,
    showSizeChanger: true,
    pageSizeOptions: [4, 8, 12, 16],
    showTotal: (total, range) => `Total ${total} items ${range[0]}-${range[1]}`,
    onChange: (page, pageSize) => {
      setPageA(page);
      setPageSizeA(pageSize);
    },
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onRow = (record, rowIndex) => {
    return {
      onClick: (event) => {
        setSelectedOrderItemNo(record?.ItemNo);
        record?.Paid !== "Yes" && setShowForm(true);
        setFormType("Edit");
      },
    };
  };

  const onDeleteConfirm = async () => {
    try {
      if (selectedRowKeys.length === 1) {
        await deleteData();
        refetch();
      } else {
        await deleteMultiple({ ids: selectedRowKeys });
        refetch();
      }
    } catch (error) {
      console.log({ error });
    }
    setShowModal(false);
    setSelectedRowKeys([]);
  };

  useEffect(() => {
    if (deletedData && !deletedDataError) {
      showToast("Successfully Deleted!", "success", faCheckCircle);
    } else if (deletedDataError) {
      showToast("Failed!", "error", faWarning);
    }
  }, [deletedData, deletedDataError]);

  useEffect(() => {
    if (data && !loading) {
      const newData = data?.data?.map((el) => ({
        ...el,
        key: el?.ItemNo,
      }));
      setListData(newData);
    }
  }, [data, loading]);

  return loading ? (
    <div className="w-full flex items-center justify-center h-screen">
      <Spinner />
    </div>
  ) : (
    <div className="">
      <ConfirmModal
        showModal={showModal}
        setShowModal={setShowModal}
        onDeleteConfirm={onDeleteConfirm}
      />
      {!showForm && (
        <div className="flex justify-between mb-2">
          <div className="mt-4 font-medium">Order Item</div>
          {!paidStatus &&
            logData.role_value !== 1 &&
            logData.role_value !== 2 && (
              <div className="my-4">
                <Button
                  type="primary"
                  onClick={() => {
                    // navigate(`/orderitem/add/${id}`);
                    setShowForm(true);
                    setFormType("Add");
                  }}
                  className="mr-2 w-[100px] h-[30px]"
                >
                  <PlusOutlined />
                  Add
                </Button>
                <Button
                  type="primary"
                  disabled={selectedRowKeys?.length === 0}
                  onClick={() => {
                    setShowModal(true);
                  }}
                  className="w-[100px] h-[30px]"
                >
                  <DeleteOutlined />
                  Delete
                </Button>
              </div>
            )}
        </div>
      )}

      {showForm && (
        <OrderItemAddForm
          setShowForm={setShowForm}
          refetch={refetch}
          type={formType}
          OrderItemNo={selectedOrderItemNo}
        />
      )}

      <GlobalTable
        columns={OrderItemColumns(hoveredRow, setShowModal, setSelectedRowKeys)}
        dataSource={listData}
        rowSelection={!paidStatus ? rowSelection : null}
        pagination={paginationConfig}
        onRow={(record, rowIndex) => {
          return {
            onMouseEnter: () => setHoveredRow(rowIndex),
            onMouseLeave: () => setHoveredRow(null),
            ...onRow?.(record, rowIndex),
          };
        }}
      />
    </div>
  );
};
