import React from "react";
import { FaArrowDownLong, FaArrowUpLong } from "react-icons/fa6";

const SortIcon = ({ sortOrder, columnKey, sortBy }) => {
  return (
    <div className="flex ml-2">
      <FaArrowUpLong
        size={10}
        className={`text-[14px] font-bold mr-[-2px] ${
          columnKey === sortBy && sortOrder === "asc"
            ? "!text-black"
            : "text-[#96A3AF]"
        }`}
      />
      <FaArrowDownLong
        size={10}
        className={`text-[14px] font-bold ${
          columnKey === sortBy && sortOrder === "desc"
            ? "!text-black"
            : "text-[#96A3AF]"
        }`}
      />
    </div>
  );
};

export default SortIcon;
