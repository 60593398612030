import React from "react";
import { Form, Switch } from "antd";

const CustomSwitch = ({
  label,
  name,
  defaultChecked,
  errorMessage,
  required,
  onChange,
  ...rest
}) => {
  return (
    <Form.Item
      label={label}
      className={"mb-1"}
      name={name}
      valuePropName="checked"
      initialValue={defaultChecked}
      rules={[{ required: required, message: `${label} is required` }]}
      validateStatus={errorMessage ? "error" : ""}
      help={errorMessage}
      labelCol={{ span: 29 }}
      wrapperCol={{ span: 29 }}
    >
      <Switch onChange={onChange} {...rest} />
    </Form.Item>
  );
};

export default CustomSwitch;
