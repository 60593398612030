import { faCheckCircle, faWarning } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import { Form, Button, Col, Row } from "antd";
import { getModifiedResponse } from "../utils/responseModifier";
import useGet from "../hooks/useGet";
import { showToast } from "../utils/toast";
import { useNavigate, useParams } from "react-router-dom";
import CommonBreadcrumb from "./CommonBreadcrumb";
import CommonButton from "./CommonButton";
import CustomInput from "./inputWidgets/CustomInput";
import usePut from "../hooks/usePut";
import {
  UnorderedListOutlined,
  CloseOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";

export const ProfileForm = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const loggedInUserData = JSON.parse(sessionStorage.getItem("userData"));
  const id = loggedInUserData.account_no;

  const url = `${process.env.REACT_APP_API_URL}/api/account/${id}`;
  const putUrl = `${process.env.REACT_APP_API_URL}/api/account/update/${id}`;

  const { data, loading, error } = useGet(url);
  console.log({ data });
  const {
    data: putData,
    loading: putLoading,
    error: putError,
    putData: putAction,
  } = usePut(putUrl);

  const onFinish = (values) => {
    try {
      putAction(values);
      !putLoading &&
        showToast("Data updated successfully!", "success", faCheckCircle);
      navigate("/accountlist");
    } catch (error) {
      showToast("Something went wrong, Please try again!", "error", faWarning);
      console.log(error);
    }
  };

  const onReset = () => {
    // form.resetFields();
    navigate("/accountlist");
  };

  useEffect(() => {
    if (!id || loading) return;
    const getAccountInfoById = () => {
      console.log(data.data);
      try {
        if (data?.data) {
          form.setFieldsValue({
            AccountNo: String(data?.data?.AccountNo),
            UserId: String(data?.data?.UserId),
            AccountName: String(data.data.AccountName),
            AccountDesc: data.data.AccountDesc
              ? String(data.data.AccountDesc)
              : "",
            Email: data.data.Email ? String(data.data.Email) : "",
          });
        } else {
          console.warn("No data found for the given ID");
        }
      } catch (error) {
        if (!loading) {
          console.log({ error });
          showToast("Error fetching data!", "error", faWarning);
        }
      }
    };

    getAccountInfoById();
  }, [id, data, loading, form]);

  const breadcrumbItems = [
    {
      label: "Account",
      href: "/accountlist",
    },
    {
      label: "Add",
      href: "account/add",
    },
  ];

  const buttonsData = [
    {
      label: "List",
      icon: <UnorderedListOutlined />,
      onClick: () => {
        navigate("/accountlist");
      },
    },
    {
      label: "Apply",
      icon: <CheckCircleOutlined />,
      onClick: () => form.submit(),
    },
    {
      label: "Cancel",
      icon: <CloseOutlined />,
      onClick: onReset,
    },
  ];

  return (
    !loading && (
      <Form
        validateTrigger={"onChange"}
        form={form}
        onFinish={onFinish}
        scrollToFirstError
        layout={"vertical"}
        autoComplete={"off"}
      >
        <div>
          <div className="flex justify-between">
            <CommonBreadcrumb breadcrumbItems={breadcrumbItems} />
            <CommonButton buttonsData={buttonsData} />
          </div>
          {!loading && (
            <div className="mt-4 p-4 bg-white rounded-lg shadow-xl">
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomInput label="Account No" name="AccountNo" />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomInput label="User Id" name="UserId" />
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomInput
                    label="Password"
                    name="password"
                    type="password"
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomInput label="Name" name="AccountName" />
                </Col>
              </Row>
              <Row gutter={[16, 16]} className="mt-2">
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomInput label="Description" name="AccountDesc" />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomInput label="Email" name="Email" />
                </Col>
              </Row>
            </div>
          )}
        </div>
      </Form>
    )
  );
};
