import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Form, Input, Button, Typography, Spin } from "antd";
import { faCheckCircle, faWarning } from "@fortawesome/free-solid-svg-icons";
import { showToast } from "../utils/toast";
import AuthLayout from "./AuthLayout";
import usePostLogin from "../hooks/usePostLogin";
import useOemData from "../hooks/useOemData";
import { Spinner } from "../components/Spinner";
import KeyIcon from "../../assets/images/vectors/KeyIcon";
import EmailIcon from "../../assets/images/vectors/EmailIcon";
import { LoadingOutlined } from "@ant-design/icons";

export const CustomerLogin = () => {
  const [isFocused, setIsFocused] = useState(false);
  const { oemNo, oemData, loading: oemDataLoading } = useOemData();
  const location = useLocation();
  // Extract query parameters using URLSearchParams
  const queryParams = new URLSearchParams(location.search);
  const mac = queryParams.get("mac");
  const product = queryParams.get("product");

  const [form] = Form.useForm();
  const securityCodeUrl = `${process.env.REACT_APP_API_URL}/api/auth/cuscode/`;
  const postUrl = `${process.env.REACT_APP_API_URL}/api/auth/cuslogin/`;
  const {
    data: codeData,
    loading: codeLoading,
    error: codeError,
    postData: sendCode,
  } = usePostLogin(securityCodeUrl);

  const {
    data: postData,
    loading: postLoading,
    error: postError,
    postData: postAction,
  } = usePostLogin(postUrl);

  const navigate = useNavigate();

  // Handle requesting the security code
  const handleRequestCode = () => {
    const email = form.getFieldValue("email");
    if (email) {
      sendCode({ email });
    }
  };

  //toast for send code
  useEffect(() => {
    if (codeError) {
      showToast(codeError?.response?.data?.message, "error", faWarning);
    } else if (!codeLoading && !codeError && codeData) {
      showToast(codeData?.message, "success", faCheckCircle);
    }
  }, [codeError, codeData, codeLoading]);

  useEffect(() => {
    if (postData) {
      sessionStorage.setItem("userData", JSON.stringify(postData));
      showToast("Successfully logged in!", "success", faCheckCircle);
      if (location.pathname.includes("upgrade")) {
        navigate("/setUpgrade");
      } else {
        navigate("/setInitial");
      }
    }
    if (postError) {
      showToast(
        "Login failed. Please check your credentials and try again.",
        "error",
        faWarning
      );
    }
  }, [postData, postError]);

  // on submit handler.
  const onSubmitHandler = async (values) => {
    try {
      const customerInfo = {
        email: values.email,
        mac,
        product,
      };
      sessionStorage.setItem("customerInfo", JSON.stringify(customerInfo));
      await postAction(values);
    } catch (error) {
      showToast(
        "Login failed. Please check your email and try again.",
        "error",
        faWarning
      );
    }
  };

  return oemDataLoading ? (
    <div className="w-full flex items-center justify-center h-screen">
      <Spinner />
    </div>
  ) : (
    <AuthLayout>
      <div className={`w-[336px]`}>
        <img
          src={require(`../../oem/${oemNo}/images/mainLogo.png`)}
          alt="Sicunet"
          className="w-[170px] mb-6 mx-auto"
        />
        <p className="mt-16 mb-2 text-2xl text-center">Welcome Back!</p>
        <p className="text-sm mb-16">
          Please enter the email associated with your account.
        </p>
        <Form
          form={form}
          layout={"vertical"}
          size={"middle"}
          autoComplete={"off"}
          validateTrigger={"onChange"}
          requiredMark={false}
          onFinish={onSubmitHandler}
        >
          <div>
            <div className="relative">
              <Form.Item
                label="Email"
                name="email"
                className="my-input text-sm mb-4 "
                rules={[
                  {
                    required: true,
                    message: "Please input your email!",
                  },
                  {
                    type: "email",
                    message: "Please enter a valid email!",
                  },
                ]}
              >
                <Input
                  className={`h-[40px] !bg-white`}
                  placeholder=" Enter email"
                  prefix={<EmailIcon />}
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => setIsFocused(false)}
                />
              </Form.Item>
              <p
                className={`absolute top-10 right-2 text-xs text-customColor hover:text-black cursor-pointer ${
                  isFocused ? "z-10" : "z-10"
                } `}
                onClick={handleRequestCode}
              >
                Send Code
              </p>
            </div>
            <Form.Item
              label="Security Code"
              name="security_code"
              className="my-input text-sm"
              rules={[
                {
                  required: true,
                  message: "Please input your security code!",
                },
              ]}
              //   style={{ marginBottom: "6px" }}
            >
              <Input.Password
                className="h-[40px]  !bg-white"
                placeholder=" Enter code"
                prefix={<KeyIcon />}
              />
            </Form.Item>
          </div>

          <Form.Item className="">
            <Button
              htmlType="submit"
              className="w-full bg-customColor text-white hover:!bg-white border-none hover:!text-customColor font-medium text-[14px] !rounded !py-4 h-[35px] "
            >
              {postLoading ? (
                <span>
                  <Spin
                    indicator={<LoadingOutlined spin />}
                    size="default"
                    className="mr-2"
                  />
                  Login
                </span>
              ) : (
                "Login"
              )}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </AuthLayout>
  );
};
