import React from "react";
const EmailIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      stroke="#B3B3B3"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M14.5 4.5v7a1 1 0 0 1-1 1h-11a1 1 0 0 1-1-1v-7m13 0a1 1 0 0 0-1-1h-11a1 1 0 0 0-1 1m13 0L8.569 8.606a1 1 0 0 1-1.138 0L1.5 4.5"
    />
  </svg>
);
export default EmailIcon;
