import { useNavigate, useParams } from "react-router-dom";
import CommonBreadcrumb from "../../../global/components/CommonBreadcrumb";
import CommonButton from "../../../global/components/CommonButton";
import { Form, Button, Col, Row } from "antd";
import CustomInput from "../../../global/components/inputWidgets/CustomInput";
import {
  CheckCircleOutlined,
  CloseOutlined,
  DeleteOutlined,
  DesktopOutlined,
  EditOutlined,
  ProductOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import useGet from "../../../global/hooks/useGet";
import { useEffect, useState } from "react";
import { showToast } from "../../../global/utils/toast";
import { faCheckCircle, faWarning } from "@fortawesome/free-solid-svg-icons";
import ConfirmModal from "../../../global/components/ConfirmModal";
import useDelete from "../../../global/hooks/useDelete";
import usePut from "../../../global/hooks/usePut";
import CustomSelect from "../../../global/components/inputWidgets/CustomSelect";
import { getModifiedResponse } from "../../../global/utils/responseModifier";

export const OrderItemInfoForm = ({ type }) => {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const logData = JSON.parse(sessionStorage.getItem("userData"));

  const [form] = Form.useForm();
  const url = `${process.env.REACT_APP_API_URL}/api/orderitem/${id}`;
  const optionUrl = `${process.env.REACT_APP_API_URL}/api/orderitem/getoptions`;
  const putUrl = `${process.env.REACT_APP_API_URL}/api/orderitem/update/${id}`;
  const deleteUrl = `${process.env.REACT_APP_API_URL}/api/orderitem/delete/${id}`;
  const { data, loading, error, refetch } = useGet(url);
  const {
    data: optionsData,
    loading: optionDataLoading,
    error: optionDataError,
  } = useGet(optionUrl);
  const {
    data: putData,
    loading: putLoading,
    error: putError,
    putData: putAction,
  } = usePut(putUrl);
  const {
    data: deletedData,
    loading: deletedDataLoading,
    error: deletedDataError,
    deleteData,
  } = useDelete(deleteUrl);

  const modifiedCustomers = getModifiedResponse(optionsData, "customers");
  const modifiedLicenses = getModifiedResponse(optionsData, "licenses");

  const onFinish = (values) => {
    try {
      if (id) {
        putAction(values);
        !putLoading &&
          showToast("Data updated successfully!", "success", faCheckCircle);
        refetch();
        // setTimeout(() => {
        //   navigate("/orderiteminfo/12");
        // }, 200);
        navigate(`/orderitem/info/${id}`);
      }
    } catch (error) {
      showToast("Something went wrong, Please try again!", "error", faWarning);
      console.log(error);
    }
  };

  const onDeleteConfirm = async () => {
    try {
      await deleteData();
    } catch (error) {
      showToast("Failed!", "error", faWarning);
    }
    setShowModal(false);
  };

  const onReset = () => {
    data?.data?.OrderNo
      ? navigate(`/orderinfo/info/${data?.data?.OrderNo}`)
      : navigate("orderlist");
  };

  useEffect(() => {
    if (!id || loading) return;
    const getOrderItemInfoById = () => {
      try {
        if (data?.data) {
          form.setFieldsValue({
            CustomerNo: data?.data?.CustomerNo,
            LicenseNo: data?.data?.LicenseInfo?.LicenseNo,
            OldModel: data?.data?.LicenseInfo?.OldModelName ?? "",
            Model: data?.data?.LicenseInfo?.ModelName ?? "",
            OldType: data?.data?.LicenseInfo?.OldType,
            Type: data?.data?.LicenseInfo?.Type,
            Options: data?.data?.Options ? data?.data?.Options : "",
            Camera: data?.data?.LicenseInfo?.Camera ?? 0,
            Channel: data?.data?.LicenseInfo?.Channel ?? 0,
            Lockset: data?.data?.LicenseInfo?.Lockset ?? 0,
            Facegate: data?.data?.LicenseInfo?.Facegate ?? 0,
            Subnode: data?.data?.LicenseInfo?.Subnode ?? 0,
            TrilogyLockset: data?.data?.LicenseInfo?.ContLock ?? 0,
            Intercom: data?.data?.LicenseInfo?.Intercom ?? 0,
          });
        } else {
          console.warn("No data found for the given ID");
        }
      } catch (error) {
        if (!loading) {
          console.log({ error });
          showToast("Error fetching data!", "error", faWarning);
        }
      }
    };

    getOrderItemInfoById();
  }, [id, data, loading]);

  useEffect(() => {
    if (deletedData && !deletedDataError) {
      showToast("Successfully Deleted!", "success", faCheckCircle);
      refetch();
      navigate(`/orderinfo/info/${data?.data?.OrderNo}`);
    } else if (deletedDataError) {
      showToast("Failed!", "error", faWarning);
    }
  }, [deletedData, deletedDataError]);

  const breadcrumbItems = [
    {
      label: "Order Item",
      href: "/orderlist",
    },
    {
      label: type === "info" ? "Information" : "Edit",
      href: type === "info" ? `/orderitem/info/${id}` : `/orderitem/edit/${id}`,
    },
  ];

  const buttonsData =
    data?.data?.OrderPaid === "Yes"
      ? [
          {
            label: "List",
            icon: <UnorderedListOutlined />,
            onClick: () => {
              data?.data?.OrderNo
                ? navigate(`/orderinfo/info/${data?.data?.OrderNo}`)
                : navigate("orderlist");
            },
          },
        ]
      : type === "edit"
      ? [
          logData.role_value !== 1 && {
            label: "Apply",
            icon: <CheckCircleOutlined />,
            onClick: () => form.submit(),
          },
          {
            label: "Cancel",
            icon: <CloseOutlined />,
            onClick: onReset,
          },
        ].filter(Boolean) // Filters out false values when roleValue is 1
      : [
          logData.role_value !== 1 && {
            label: "Edit",
            icon: <EditOutlined />,
            onClick: () => {
              navigate(`/orderitem/edit/${id}`);
            },
          },
          logData.role_value !== 1 && {
            label: "Delete",
            icon: <DeleteOutlined />,
            onClick: () => {
              setShowModal(true);
            },
          },
          {
            label: "List",
            icon: <UnorderedListOutlined />,
            onClick: () => {
              data?.data?.OrderNo
                ? navigate(`/orderinfo/info/${data?.data?.OrderNo}`)
                : navigate("orderlist");
            },
          },
        ].filter(Boolean);

  return (
    <div>
      <div className="flex justify-between">
        <CommonBreadcrumb breadcrumbItems={breadcrumbItems} />
        <CommonButton buttonsData={buttonsData} />
      </div>
      {!loading && (
        <Form
          validateTrigger={"onChange"}
          form={form}
          onFinish={onFinish}
          scrollToFirstError
          layout={"vertical"}
          autoComplete={"off"}
        >
          <div className="mt-6 rounded-[4px] overflow-hidden">
            <div className="bg-gray-100 p-2">
              <ProductOutlined />
              <span className="text-md ml-2">Order Item</span>
            </div>
            <div className="p-2">
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomSelect
                    label="Customer"
                    name="CustomerNo"
                    options={modifiedCustomers}
                    placeholder="Please select"
                    disabled={type === "info"}
                    required
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomSelect
                    label="License"
                    name="LicenseNo"
                    options={modifiedLicenses}
                    placeholder="Please select"
                    disabled={type === "info"}
                    required
                  />
                </Col>
              </Row>
            </div>
          </div>
          {type == "info" && (
            <div className="mt-6 rounded-[4px] overflow-hidden">
              <div className="bg-gray-100 p-2">
                <DesktopOutlined />
                <span className="text-md ml-2">License Information</span>
              </div>
              <div className="p-2">
                {data?.data?.LicenseInfo?.OldType !== 0 ? (
                  <>
                    <Row gutter={[16, 16]}>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <CustomInput
                          label="Old Model"
                          name="OldModel"
                          disabled={type === "info"}
                        />
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <CustomInput
                          label="Model"
                          name="Model"
                          disabled={type === "info"}
                        />
                      </Col>
                    </Row>
                    <Row gutter={[16, 16]} className="">
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <CustomInput
                          label="Old Type"
                          name="OldType"
                          disabled={type === "info"}
                        />
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <CustomInput
                          label="Type"
                          name="Type"
                          disabled={type === "info"}
                        />
                      </Col>
                    </Row>
                  </>
                ) : (
                  <>
                    <Row gutter={[16, 16]}>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <CustomInput
                          label="Model"
                          name="Model"
                          disabled={type === "info"}
                        />
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <CustomInput
                          label="Type"
                          name="Type"
                          disabled={type === "info"}
                        />
                      </Col>
                    </Row>
                  </>
                )}
                <Row gutter={[16, 16]} className="">
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <CustomInput
                      label="Options"
                      name="Options"
                      disabled={type === "info"}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <CustomInput
                      label="Camera"
                      name="Camera"
                      disabled={type === "info"}
                    />
                  </Col>
                </Row>
                <Row gutter={[16, 16]} className="">
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <CustomInput
                      label="Channel"
                      name="Channel"
                      disabled={type === "info"}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <CustomInput
                      label="Lockset"
                      name="Lockset"
                      disabled={type === "info"}
                    />
                  </Col>
                </Row>
                <Row gutter={[16, 16]} className="">
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <CustomInput
                      label="Facegate"
                      name="Facegate"
                      disabled={type === "info"}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <CustomInput
                      label="Subnode"
                      name="Subnode"
                      disabled={type === "info"}
                    />
                  </Col>
                </Row>
                <Row gutter={[16, 16]} className="">
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <CustomInput
                      label="Trilogy Lockset"
                      name="TrilogyLockset"
                      disabled={type === "info"}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <CustomInput
                      label="Intercom"
                      name="Intercom"
                      disabled={type === "info"}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          )}
        </Form>
      )}
      <ConfirmModal
        showModal={showModal}
        setShowModal={setShowModal}
        onDeleteConfirm={onDeleteConfirm}
      />
    </div>
  );
};
